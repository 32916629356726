import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import { TextInput, EmailInput, PhoneInput, DropdownInput } from "../Logic/FormInput";
import LoadingOverlay from "react-loading-overlay";
import Footer from "../Component/Footer_Com";
import NavbarCenter from "../Component/NavigationBar_Com";
import city from "../Assets/Data/Cities";
import { postMerchant } from "../Logic/Share_Logic";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RegisterMerchantHookForm_Page() {

    const history = useHistory();
    const numberRegex = /^[0-9]*$/;
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [selectedCity, setSelectedCity] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [picNumber, setPICNumber] = useState("");
    const [cityData, setCityData] = useState([]);

    useEffect(() => {
        let res = [];
        city.forEach(val => {
            res.push({
                value: val, label: val,
            });
        });
        setCityData(res);
        setIsLoading(false);
    }, []);

    
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
            // only allow numbers after first input
            else {
                if ( numberRegex.test(value.substring(1)) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
        }
        else {
            if ( event.target.name === "phone_number" ) {
                setPhoneNumber(event.target.value);
            }
            else if ( event.target.name === "pic_number" ) {
                setPICNumber(event.target.value);
            }
        }
    }
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Send new merchant data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        if ( data.city ) {
            if ( data.city.value !== "Lainnya" ) {
                data["city"] = data.city.value;
            }
            else {
                data["city"] = data.city_input;
            }
        }
        let resp = await postMerchant(data);
        if ( resp[0] && resp[0].status === 200 ) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1500);
            history.go();
        }
        else {
            console.log(resp);
            if ( resp[1] && resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else if ( resp[1] ) {
                setErrorText(resp[1].message);
            }
            else {
                setErrorText("Gagal mendaftar sebagai merchant. Silakan coba hubungi admin.");
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <LoadingOverlay active={isLoading} spinner text="Mohon tunggu sebentar">
            <NavbarCenter />
            <div className="content form bg merchantFormWrapper" style={{ marginTop: "0" }}>
                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Melakukan Registrasi
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h4 className="form-title">Form Pendaftaran Merchant</h4>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama Usaha *"
                                        name="name"
                                        placeholder="Nama Usaha"
                                        className="form-left-field"
                                        validation={register({ required: "*nama usaha harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama Pemilik *"
                                        name="applier_name"
                                        placeholder="Nama Pemilik"
                                        className="form-right-field"
                                        validation={register({ required: "*nama pemilik harus diisi" })}
                                        errors={errors.applier_name}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <EmailInput
                                        label="Email *"
                                        name="email"
                                        placeholder="Email"
                                        className="form-left-field"
                                        validation={register}
                                        errors={errors.email}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PhoneInput
                                        label="Nomor Kontak *"
                                        name="phone_number"
                                        placeholder="Nomor Kontak"
                                        className="form-right-field"
                                        value={phoneNumber}
                                        onChange={onChangePhoneNumber}
                                        validation={register}
                                        errors={errors.phone_number}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama PIC"
                                        name="pic"
                                        placeholder="Nama PIC"
                                        className="form-left-field"
                                        validation={register}
                                        errors={errors.pic}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PhoneInput
                                        label="Nomor PIC"
                                        name="pic_contact"
                                        placeholder="Nomor PIC"
                                        className="form-right-field"
                                        value={picNumber}
                                        onChange={onChangePhoneNumber}
                                        validation={register}
                                        isNotRequired={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kategori Bisnis *"
                                        name="business_category"
                                        placeholder="Kategori"
                                        className="form-left-field"
                                        validation={register({
                                            required: "*kategori bisnis harus diisi (contoh: FnB, Retail, dll)" 
                                        })}
                                        errors={errors.business_category}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    <DropdownInput
                                        label="Kota"
                                        name="city"
                                        className="form-right-field add-margin-bottom tf-label"
                                        keepData={(val) => setSelectedCity(val)}
                                        disableClearable={false}
                                        control={control}
                                        options={cityData}
                                        getOptionLabel={(option) => option.label}
                                        value={selectedCity}
                                        required={false}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: selectedCity &&
                                    selectedCity.value == "Lainnya"? "block" : "none" }}
                                >
                                    <TextInput
                                        label="Nama Kota *"
                                        name="city_input"
                                        placeholder="Nama Kota Lainnya"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: 
                                            selectedCity && selectedCity.value == "Lainnya"?
                                                "*nama kota lainnya harus diisi" : false
                                        })}
                                        errors={errors.city_input}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Usaha *"
                                        name="address"
                                        placeholder="Alamat Usaha"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat usaha harus diisi" })}
                                        errors={errors.address}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        multiline={true}
                                        rows={3}
                                        label="Media Sosial"
                                        name="sosmed"
                                        placeholder="Media Sosial"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12}> 
                                    <TextInput
                                        multiline={true}
                                        rows={3}
                                        label="Member Benefit *"
                                        name="member_benefit"
                                        placeholder="Promo Untuk Member"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*promo member harus diisi" })}
                                        errors={errors.member_benefit}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}> 
                                    <TextInput
                                        multiline={true}
                                        rows={3}
                                        label={`Syarat & Ketentuan *`}
                                        name="terms_and_conditions"
                                        placeholder={`Syarat & Ketentuan Untuk Member`}
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: `*syarat & ketentuan harus diisi` })}
                                        errors={errors.terms_and_conditions}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                {/* <Grid item xs={4} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        label="Kode"
                                        placeholder="-"
                                        className="verification-field"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    <Button
                                        id="verification-btn"
                                        variant="contained"
                                    >
                                        Kirim Kode Verifikasi
                                    </Button>
                                </Grid> */}
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn member"
                            >DAFTAR</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </LoadingOverlay>
    )
}
