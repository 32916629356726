import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import "../Style_v2/Register_Style.css";

class RegisterV2 extends Component {
    render() {
        return (
            <Container fluid className="bg register">
                <NavbarCenter />

                <Row className="bgImage register">
                    <Col md={12} className="vertically">
                        <div className="new-container">
                            <div className="register-content">
                                <h5 className="register-quotes">
                                    Pendaftaran member Idepreneurs
                                    melalui aplikasi Idepreneurs Club yang
                                    dapat diunduh di App Store dan Play
                                    Store
                                </h5>
                            </div>
                            <div className="qr-section">
                                <div>
                                    <img
                                        src="/Assets/QR/qr-ide-ios.png"
                                        className="qr-app"
                                    />
                                </div>
                                <div>
                                    <a href="https://apps.apple.com/us/app/idepreneurs-club/id1635974621" target="_blank">
                                        <img
                                            src="/Assets/btn-app-store.png"
                                            className="btn-app"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="qr-section">
                                <div>
                                    <img
                                        src="/Assets/QR/qr-ide-android.png"
                                        className="qr-app"
                                    />
                                </div>
                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.idepreneurs.android&pli=1" target="_blank">
                                        <img
                                            src="/Assets/btn-play-store.png"
                                            className="btn-app"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="footer-register">
                    <Footer />
                </div>
            </Container>
        );
    }
};

export default RegisterV2;