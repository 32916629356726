import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete } from '@material-ui/lab/';
// import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControl,
  InputLabel,
  TextField,
  RadioGroup,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from "@material-ui/icons/Close";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

// Field for basic input text
export function TextInput(props) {
  return (
    <>
      <TextField
        multiline={props.multiline}
        rows={props.rows}
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        variant="outlined"
        className={`${props.className} ${props.errors ? "error-style" : ""}`}
        inputRef={props.validation}
        disabled={props.disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </>
  );
}

// Field for email input
export function EmailInput(props) {
  return (
    <>
      <TextField
        type="email"
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        variant="outlined"
        className={`${props.className} ${props.errors ? "error-style" : ""}`}
        disabled={props.disabled}
        inputRef={props.validation({
          required: "*email harus diisi",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "*alamat email tidak valid",
          },
        })}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </>
  );
}

// Field for phone number input
export function PhoneInput(props) {
  return (
    <>
      <TextField
        variant="outlined"
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        className={`${props.className} ${props.errors ? "error-style" : ""}`}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        inputRef={props.validation({
          required: props.isNotRequired ? false : "*nomor telepon harus diisi",
          pattern: {
            value: /^[+-]?\d+$/,
            message: "*format tidak valid",
          },
          minLength: {
            value: 8,
            message: "*nomor telepon terlalu pendek",
          },
          maxLength: {
            value: 16,
            message: "*nomor telepon terlalu panjang",
          },
        })}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </>
  );
}

// Field for date input
export function DateInput(props) {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          name={props.name}
          label={props.label}
          format="dd/MM/yyyy"
          maxDate={props.maxDate}
          value={props.value}
          onChange={props.onChange}
          className={`${props.className} ${props.errors ? "error-style" : ""}`}
          disabled={props.disabled}
          inputRef={props.validation}
          inputVariant={props.variant}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
        />
      </MuiPickersUtilsProvider>
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </>
  );
}

// Field for clearable date input
export function DateClearableInput(props) {
  const [open, setOpen] = useState(false);
  return (
      <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  clearable
                  open={open}
                  name={props.name}
                  label={props.label}
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  maxDate={props.maxDate}
                  value={props.value}
                  onChange={props.onChange}
                  className={`${props.className} ${props.errors? "error-style" : ""}`}
                  disabled={props.disabled}
                  inputRef={props.validation}
                  inputVariant="filled"
                  onClose={() => {
                    setOpen(false);
                  }}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                      endAdornment: (
                          <React.Fragment>
                            <IconButton
                              onClick={props.clearDate}
                              style={{ margin: "-0.5em", marginRight: "-7px" }}
                              disabled={props.disabled}
                            >
                              <CancelOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setOpen(true)}
                              style={{ margin: "-0.5em" }}
                              disabled={props.disabled}
                            >
                              <EventIcon />
                            </IconButton>
                          </React.Fragment>
                      ),
                      readOnly: true,
                  }}
                  InputAdornmentProps={{
                      position: "start",
                      style: {
                        display: "none"
                      }
                  }}
                  disableFuture={props.disableFuture}
                  disablePast={props.disablePast}
              />
          </MuiPickersUtilsProvider>
          {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
      </>
  )
}

// Field for choosing one option using radio button
export function RadioButtonInput(props) {
  return (
    <FormControl component="fieldset" style={{ width: "100%" }}>
      <InputLabel className="rb-label">{props.inputLabel}</InputLabel>
      <Controller
        rules={props.rules}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        as={
          <RadioGroup
            value={props.name}
            onChange={props.onRBChange}
            style={{ display: "block", margin: "5px 0 20px" }}
          >
            {props.options}
          </RadioGroup>
        }
      />
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </FormControl>
  );
}

// Field for choosing many options using checkbox
export function ChoiceboxInput(props) {
  return (
    <>
      <FormControl
        component="fieldset"
        className="cb-item"
        style={{ width: "100%" }}
      >
        <InputLabel className="cb-label">{props.inputLabel}</InputLabel>
        <FormGroup style={{ margin: "5px 0 20px" }}>{props.options}</FormGroup>
      </FormControl>
    </>
  );
}

// Field for grouped dropdown
export function GroupedDropdown(props) {
  return (
    <>
      <label className={`grouped-label ${props.labelPosition}`}>
        {props.label}
      </label>
      <Controller
        as={Select}
        name={props.name}
        className={props.className}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        disabled={props.disabled}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
      />
      {props.errors && (
        <p className={props.errorClassName}>{props.errors.message}</p>
      )}
    </>
  );
}

// Field for selection/dropdown input
export function DropdownInput(props) {
  return (
      <>
          <Controller
              name={props.name}
              as={ ({ onChange }) => 
                  (<Autocomplete
                      disableClearable={props.disableClearable}
                      options={props.options}
                      getOptionLabel={props.getOptionLabel}
                      // onChange={(_, data) => onChange(data)}
                      onChange={(_, data) => {
                          // UPDATE THE DROPDOWN VALUE
                          onChange(data);
                          // KEEP THE DATA WHEN OTHER EVENT HAPPEN
                          props.keepData(data)
                      }}
                      value={props.value}
                      defaultValue=""
                      className={`${props.className} ${props.errors? "error-style" : ""}`}
                      disabled={props.disabled}
                      style={props.style}
                      renderInput={(params) =>
                          <TextField
                              {...params}
                              inputRef={props.inputRef}
                              label={props.label}
                              placeholder="Pilih"
                              variant="outlined"
                              required={props.required}
                              InputLabelProps={{
                                shrink: true,
                              }}
                          />}
                  />)
              }
              control={props.control}
              rules={props.rules}
              defaultValue={props.defaultValue}
          />
          {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
      </>
  )
}

// Field for upload image and show the preview
export function ImageInput(props) {
  const [state, setState] = useState({
    image: null,
    imgPath: "Pilih Gambar",
    canceledImage: false,
    deleteResponse: 0,
  });
  const max = 2097152;

  const validateImage = (value) => {
    return value[0] ? value[0].size <= max : props.isEdit === true;
  };

  const inputImage = (event) => {
    let obj = new FormData();
    obj.append("image", event.target.files[0]);
    setState({
      ...state,
      image: URL.createObjectURL(event.target.files[0]),
      formData: obj,
      imgPath: event.target.files[0].name,
      canceledImage: false,
      deleteResponse: 0,
    });
  };

  const clearImage = (event) => {
    document.getElementById("selectedFile_" + props.name).value = null;
    setState({
      ...state,
      // image: require("../Assets/imageDefault.png").default,
      image: require("../Assets/imageDefault.png"),
      formData: null,
      imgPath: "Pilih Gambar",
      canceledImage: true,
      deleteResponse: 200,
    });
  };

  return (
    <div className={props.overallClassName}>
      <div
        style={{
          position: "relative",
          width: "min-content",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          className="delete-img-btn"
          style={{ display: state.formData ? "block" : "none" }}
        >
          <CloseIcon onClick={() => clearImage()} className="can-delete" />
        </div>
        <img
          width="100%"
          style={{
            width: "10rem",
            height: "10rem",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          src={state.image ? state.image : "Assets/image.png"}
          alt="img"
        />
      </div>
      <div className={props.disabled ? "" : "image-info"}>
        <p
          style={{
            marginBottom: "10px",
            paddingLeft: "10px",
            textAlign: props.disabled ? "center" : "left",
          }}
        >
          <b>{props.label}</b>
        </p>
        <div>
          <label style={{ width: "100%", padding: "0 10px" }}>
            <input
              style={{ display: "none" }}
              accept="image/*"
              type="file"
              size="60"
              name={props.name}
              onChange={inputImage}
              disabled={props.disabled}
              id={"selectedFile_" + props.name}
              ref={
                !props.isEdit
                  ? props.validation({
                      required: props.error_label + " harus diisi",
                      validate: (value) =>
                        validateImage(value) ||
                        "Gambar yang anda masukkan melebihi batas maksimal (2MB)",
                    })
                  : props.validation({
                      required: false,
                      validate: (value) =>
                        validateImage(value) ||
                        "Gambar yang anda masukkan melebihi batas maksimal (2MB)",
                    })
              }
            />
            <div className={props.disabled ? "hide" : ""}>
              <span className="img-name">{state.imgPath}</span>
              <span className="browse-btn">Browse</span>
            </div>
          </label>
          {props.errors && (
            <span style={{ color: "red", width: "100%", display: "inherit" }}>
              <small>{props.errors.message}</small>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
