import React, { useEffect, useState } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
// import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, TextField, Button } from "@material-ui/core/";
import Footer from "../Component/Footer_Com";
import "../Style/Register_Style.css";
import "../Style/Event_Style.css";
import "../Style_v2/OurProgram_Style.css";
import { useHistory } from "react-router-dom";
// import queryString from "query-string";
// import CardCustom from "../Component/CardCustom_Com";
import EventHome from "../Component_v2/EventHome_Com";
import Spinner from "../Component/Loading_Com";
import cities from "../Data/data";
import { DateClearableInput } from "../Logic/FormInput";
import moment from "moment";
import { getEventCategories, getFilteredEvents, getLocations,
  formatDate } from "../Logic/Share_Logic";

// Make the website stay still for __ ms
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

function OurPrograms() {

  // Form State
  const [locCategs, setLocCategs] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [counter, setCounter] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [dataEvent, setDataEvent] = useState([]);
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState({
    city: null,
    category: null,
    locCategory: null,
    locName: null,
    first_date: null,
    last_date: null,
  });

  // Page State
  const numberRegex = /^[0-9]*$/;
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const updateURLParams = (url) => {
    let result = url;
    const search = history.location.search;
    const params = new URLSearchParams(search);
    if ( params.get("category") ) {
      result += ("&category=" + params.get("category"));
    }
    if ( params.get("city") ) {
      result += ("&city=" + params.get("city"));
    }
    if ( params.get("start_date") ) {
      result += ("&start_date=" + params.get("start_date"));
    }
    if ( params.get("end_date") ) {
      result += ("&end_date=" + params.get("end_date"));
    }
    return result;
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEventCategories();
  }, []);

  // Get event categories
  const fetchEventCategories = async () => {
    setIsLoading(true);
    let resp = await getEventCategories();
    if ( resp[0] && resp[0].status === 200 ) {
      let result = [];
      resp[0].data.forEach(res => {
        result.push({
          label: res.name,
          value: res._id,
        })
      });
      setCategories(result);
      
      const search = history.location.search;
      const params = new URLSearchParams(search);
      if ( params.get("category") ) {
        fetchEvents(result);
      }
      else {
        fetchEvents();
      }
    }
    else {
      setIsLoading(false);
      console.log(resp);
    }
  }
  // Get events
  const fetchEvents = async (categs) => {
    setIsLoading(true);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const data = {
      category: params.get("category")? { value: params.get("category") } : null,
      city: params.get("city")? params.get("city") : null,
      location_id: params.get("location_id")? params.get("location_id") : null,
      first_date: params.get("start_date")? params.get("start_date") : null,
      last_date: params.get("end_date")? params.get("end_date") : null,
    };

    setState({
      ...state,
      category: categs? categs.filter(res => res["value"] === params.get("category"))[0]
        : data.category,
      city: data.city,
      first_date: data.first_date,
      last_date: data.last_date,
    })

    const page = params.get("page")? params.get("page") : 1;
    let resp = await getFilteredEvents(data, page, 12);
    
    if ( resp[0] && resp[0].status === 200 ) {
      setCounter(page);
      setCurrPage(page);
      setTotalPage(Math.ceil(resp[0].data.total / 12));
      setDataEvent(resp[0].data.events);
      getAllLocations(data.location_id);
    }
    else {
      console.log(resp);
    }
    setIsLoading(false);
  };
  const getAllLocations = async(location_id) => {
    let resp = await getLocations();
    if ( resp[0] && resp[0].status === 200 ) {
      let result = resp[0].data;
      let categs = [], names = [];
      result.forEach(res => {
          if ( categs.filter(temp => temp.category === res.category).length < 1 ) {
              categs.push(res);
          }
          if ( res.category !== "Pusat" && res.category !== "Luar Wilayah" ) {
              names.push(res);
          }
      });
      setLocCategs(categs);
      setLocationNames(names);
      if ( location_id ) {
        setState({
          ...state,
          locCategory: categs.filter(res => res._id === location_id)[0],
          locName: names.filter(res => res._id === location_id)[0],
        });
      }
    }
    else {
      console.log(resp);
    }
    setIsLoading(false);
  }

  // Change page when user click next/prev arrow button
  const handlePagination = async (type) => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    let resp = "";
    let tempPage = 0;
    if ( type === 'prev' ) {
      resp = await getFilteredEvents(state, parseInt(counter) - 1, 12);
      setCounter(parseInt(counter) - 1);
      setCurrPage(parseInt(counter) - 1);
      tempPage = parseInt(counter) - 1;
    }
    else if ( type === 'next' ) {
      resp = await getFilteredEvents(state, parseInt(counter) + 1, 12);
      setCounter(parseInt(counter) + 1);
      setCurrPage(parseInt(counter) + 1);
      tempPage = parseInt(counter) + 1;
    }
    
    if ( resp[0] && resp[0].status === 200 ) {
      setDataEvent(resp[0].data.events);
      let parameter = "?page=" + tempPage;
      parameter = updateURLParams(parameter);
      history.replace({
        pathname: '/OurProgram',
        search: parameter,
      });
    }
    else {
      console.log(resp);
    }
    setIsLoading(false);
  };

  // Update value on pagination textfield
  const onChangePagination = (value) => {
    if ( numberRegex.test(value) ) {
      if ( value === "0" ) {
        setCounter(1);
      }
      else if ( parseInt(value) > parseInt(totalPage) ) {
        setCounter(totalPage);
      }
      else {
        setCounter(value);
      }
    }
  };

  // Apply change page on-blur pagination textfield
  const onBlurPagination = async () => {
    if ( counter ) {
      window.scrollTo(0, 0);
      setIsLoading(true);
      let resp = await getFilteredEvents(state, counter, 12);
      if ( resp[0] && resp[0].status === 200 ) {
        setCurrPage(counter);
        setDataEvent(resp[0].data.events);
        let parameter = "?page=" + counter;
        parameter = updateURLParams(parameter);
        history.replace({
          pathname: '/OurProgram',
          search: parameter,
        });
      }
      else {
        console.log(resp);
      }
      await sleep(1000);
      setIsLoading(false);
    }
  };

  // Filter event
  const onSubmit = async (data) => {
    setIsLoading(true);
    data["category"] = state.category;
    data["city"] = state.city;
    if ( state.locCategory ) {
      if ( state.locCategory.category === "Pusat" || state.locCategory.category === "Luar Wilayah" ) {
        data["location_id"] = state.locCategory._id;
      }
      else {
        data["location_id"] = state.locName._id;
      }
    }
    else {
      data["location_id"] = null;
    }
    data["first_date"] = data.first_date? formatDate(data.first_date) : "";
    data["last_date"] = data.last_date? formatDate(data.last_date) : "";
    let parameter = "?page=1";
    if ( data.category ) {
      parameter += ("&category=" + data.category.value);
    }
    if ( data.city ) {
      parameter += ("&city=" + data.city);
    }
    if ( data.location_id ) {
      parameter += ("&location_id=" + data.location_id);
    }
    if ( data.first_date ) {
      parameter += ("&start_date=" + data.first_date);
    }
    if ( data.last_date ) {
      parameter += ("&end_date=" + data.last_date);
    }
    let resp = await getFilteredEvents(data, 1, 12);
    if ( resp[0] && resp[0].status === 200 ) {
      setTotalPage(Math.ceil(resp[0].data.total / 12));
      setDataEvent(resp[0].data.events);
      setCounter(1);
      setCurrPage(1);
      history.replace({
        pathname: '/OurProgram',
        search: parameter,
      });
    }
    else if ( resp[0] && resp[0].status === 204 ) {
      setTotalPage(1);
      setDataEvent([]);
      setCounter(1);
      setCurrPage(1);
    }
    else {
      console.log(resp);
    }
    setIsLoading(false);
  };

  return (
    <Grid container className="bg">
      <NavbarCenter />
      <div className="bg-image-ourProgram">
        <div className="form-input">
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <h3 className="judul">Kegiatan</h3>
          </Grid>
        </div>
        { isLoading?
          <Spinner /> :
          <>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={4} lg={3} style={{ paddingRight: 15, paddingTop: 20 }}>
                <form onSubmit={handleSubmit(onSubmit)} className="filter-form">
                  <h5 className="filter-title">Filter</h5>
                  {/* Kategori Acara */}
                  <Autocomplete
                    value={state.category}
                    options={categories}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => {
                      setState({
                        ...state,
                        category: value,
                      });
                    }}
                    className="full-width add-margin-bottom dropdown-filter"
                    renderInput={(params) =>
                      <TextField
                          {...params}
                          // inputRef={props.inputRef}
                          label="Kategori Acara"
                          placeholder="Pilih"
                          variant="filled"
                          className="input-filter"
                          required={false}
                          InputLabelProps={{ shrink: true }}
                      />}
                  />
                  {/* Kategori Wilayah */}
                  <Autocomplete
                    value={state.locCategory}
                    options={locCategs}
                    getOptionLabel={(option) => option.category}
                    onChange={(_, value) => {
                      setState({
                        ...state,
                        locCategory: value,
                        locName: null,
                      });
                    }}
                    className="full-width add-margin-bottom dropdown-filter"
                    renderInput={(params) =>
                      <TextField
                          {...params}
                          // inputRef={props.inputRef}
                          label="Kategori Wilayah"
                          placeholder="Pilih"
                          variant="filled"
                          className="input-filter"
                          required={false}
                          InputLabelProps={{ shrink: true }}
                      />}
                  />
                  {/* Nama Wilayah */}
                  { state.locCategory && state.locCategory.category !== "Pusat" && state.locCategory.category !== "Luar Wilayah" ?
                    <Autocomplete
                      value={state.locName}
                      options={locationNames.filter(res => res.category === state.locCategory.category)}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setState({
                          ...state,
                          locName: value,
                        });
                      }}
                      disabled={!state.locCategory || state.locCategory.category === "Pusat" ||
                        state.locCategory.category === "Luar Wilayah" ? true : false}
                      className="full-width add-margin-bottom dropdown-filter"
                      renderInput={(params) =>
                        <TextField
                            {...params}
                            // inputRef={props.inputRef}
                            label="Nama Wilayah"
                            placeholder="Pilih"
                            variant="filled"
                            className="input-filter"
                            required={false}
                            InputLabelProps={{ shrink: true }}
                        />}
                    /> : <></>
                  }
                  {/* Kota Lokasi Acara */}
                  <Autocomplete
                    value={state.city}
                    options={cities}
                    // getOptionLabel={(option) => option.label}
                    onChange={(_, value) => {
                      setState({
                        ...state,
                        city: value,
                      });
                    }}
                    className="full-width add-margin-bottom dropdown-filter"
                    renderInput={(params) =>
                      <TextField
                          {...params}
                          // inputRef={props.inputRef}
                          label="Kota Lokasi Acara"
                          placeholder="Pilih"
                          variant="filled"
                          className="input-filter"
                          required={false}
                          InputLabelProps={{ shrink: true }}
                      />}
                  />
                  {/* Tanggal Awal */}
                  <DateClearableInput
                      label="Tanggal Awal"
                      name="first_date"
                      value={state.first_date}
                      onChange={(date) => {
                          if (date) setState({ ...state, first_date: moment(date) })
                          else setState({ ...state, first_date: null })
                      }}
                      clearDate={() => setState({ ...state, first_date: null })}
                      className="full-width add-margin-bottom input-filter"
                      validation={register}
                      disableFuture={true}
                  />
                  {/* Tanggal Akhir */}
                  <DateClearableInput
                      label="Tanggal Akhir"
                      name="last_date"
                      value={state.last_date}
                      onChange={(date) => {
                          if (date) setState({ ...state, last_date: moment(date) })
                          else setState({ ...state, last_date: null })
                      }}
                      clearDate={() => setState({ ...state, last_date: null })}
                      className="full-width input-filter"
                      validation={register}
                      disableFuture={true}
                  />
                  {/* Submit Button */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="add-new-btn filter-btn"
                    style={{
                      borderRadius: "8px", paddingTop: "28px", paddingBottom: "28px"
                    }}
                  >Filter</Button>
                </form>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <EventHome
                  eventData={dataEvent}
                  counter={counter}
                  currPage={currPage}
                  totalPage={totalPage}
                  onChangePagination={(e) => onChangePagination(e.target.value)}
                  onBlurPagination={() => onBlurPagination()}
                  handlePagination={(type) => handlePagination(type)}
                />
              </Grid>
            </Grid>
          </Grid>
          </>
        }
      </div>
      <Footer />
    </Grid>
  );
}

// class OurProgram extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       eventData: null,
//       activeData: { id: 0, image: null, title: null, description: null },
//       open: true,
//       windowsWidth: 0,
//     };
//   }
//   render() {
//     return (
//       <Container fluid className="bg">
//         <NavbarCenter />
//         <Row className="bgImage">
//           <Col sm={{ span: 10, offset: 1 }}>
//             <Row>
//               <Col className="contentSpacing w-100">
//                 {this.state.windowsWidth < 767
//                   ? this.generateProgramM()
//                   : this.generateProgram()}
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//         <Footer />
//       </Container>
//     );
//   }

//   componentDidMount() {
//     // const values = queryString.parse(this.props.location.search);
//     this.fetchDataOurProgram();
//     this.updateWindowDimensions();
//     window.addEventListener("resize", this.updateWindowDimensions);
//   }

//   componentWillUnmount() {
//     window.removeEventListener("resize", this.updateWindowDimensions);
//   }

//   updateWindowDimensions = () => {
//     this.setState({ windowsWidth: window.innerWidth });
//   };

//   async fetchDataOurProgram() {
//     var eventCategoryData = await getEventCategory();
//     console.log(eventCategoryData)
//     if (eventCategoryData[0] === true) {
//       this.setState({
//         eventData: eventCategoryData[1],
//       });
//       this.setActiveData(0);
//     }
//   }

//   setActiveData(idData) {
//     this.setState({
//       activeData: this.state.eventData[idData],
//       open: false,
//     });
//   }

//   fade() {
//     this.setState({
//       open: true,
//     });
//   }

//   generateProgram() {
//     var program = [];
//     var programList = [];
//     program.push(
//       <Row key="-2" className={this.state.eventData === null ? "d-none" : ""}>
//         <Col sm={6}>
//           <h3 className="judul animation">{this.state.activeData.title}</h3>
//           <p className="basic animation">{this.state.activeData.description}</p>
//           <Link to={`/Event?category=${this.state.activeData.eventCode}`}>
//             <Button className="detailButton">Lihat Selengkapnya</Button>
//           </Link>
//         </Col>
//         <Col sm={6} className="homeEventImgContainer">
//           <Image
//             className="animation homeEventImage"
//             fluid
//             src={this.state.activeData.image}
//           />
//         </Col>
//       </Row>
//     );
//     if (this.state.eventData === null) {
//       programList.push(
//         <div
//           key="-1"
//           className="merchantNull"
//           style={{
//             height: window.outerHeight * 0.75,
//           }}
//         >
//           Kegiatan tidak ditemukan
//         </div>
//       );
//     } else {
//       console.log(this.state.eventData);
//       this.state.eventData.forEach((event) => {
//         programList.push(
//           <Card
//             key={event.id}
//             className={
//               this.state.activeData.id === event.id
//                 ? "eventCardActive"
//                 : "eventCard"
//             }
//             onClick={() => this.setActiveData(event.id)}
//           >
//             <Card.Img variant="top" src={event.image} height="120" />
//             <Card.Body className="cardBody">
//               <Card.Title className="cardTitle">{event.title}</Card.Title>
//             </Card.Body>
//           </Card>
//         );
//       });
//     }
//     program.push(
//       <Row key="-3" className="homeEventList">
//         {programList}
//       </Row>
//     );
//     return program;
//   }

//   generateProgramM() {
//     var programList = [];
//     if (this.state.eventData === null) {
//       programList.push(
//         <div
//           className="merchantNull"
//           style={{
//             height: window.outerHeight * 0.75,
//           }}
//         >
//           Kegiatan tidak ditemukan
//         </div>
//       );
//     } else {
//       this.state.eventData.map((event) => {
//         console.log(event);
//         programList.push(
//           <CardCustom
//             key={event.id}
//             image={event.image}
//             title={event.title}
//             date={""}
//             description={event.description}
//             eventID={event.idEvent}
//             pageLink={`/Event?category=${event.eventCode}`}
//           />
//         );
//         return "";
//       });
//     }
//     return programList;
//   }
// }

export default OurPrograms;
