import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import Footer from "../Component/Footer_Com";
import Pengurus from "../Component/Pengurus_Com";
import { getExecutiveBoards } from "../Logic/Share_Logic";
import "../Style/Executive_Style.css";

class Executive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      executiveData: [], 
      activeData: { id: 0, name: "", title: "", desc: "" },
      isLoading: true,
      open: true,
    };
  }
  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Mohon tunggu sebentar"
      >
        <Container fluid className="bg">
          <NavbarCenter />
          <Row className="bgImage" style={{ marginBottom: "3rem" }}>
            <Col sm={{ span: 10, offset: 1 }}>
              <Pengurus data={this.state.executiveData} />
            </Col>
          </Row>
          <Footer />
        </Container>
      </LoadingOverlay>
    );
  }

  componentDidMount() {
    this.setActiveData(0);
    this.getData();
  }

  async getData() {
    let resp = await getExecutiveBoards();
    if ( resp[0] && resp[0].status === 200 ) {
      const pengurus = resp[0].data.filter(res => res.category === "pengurus");
      let result = [];

      for ( let i=0; i < pengurus.length; i++ ) {
        result.push({
          id: pengurus[i]._id,
          nama: pengurus[i].member_id.name,
          cabang:
            pengurus[i].location_id.category === "Region" ?
              ("Pengurus Region - " + pengurus[i].location_id.name)
            : pengurus[i].location_id.category === "Chapter" ?
              ("Pengurus Chapter - " + pengurus[i].location_id.name)
            : ("Pengurus " + pengurus[i].location_id.name),
          jabatan: pengurus[i].title,
          foto: pengurus[i].image_executiveboard,
          sorting: pengurus[i].location_id.category === "Pusat" ? "A"
            : pengurus[i].location_id.category === "Luar Wilayah" ? "B"
            : pengurus[i].location_id.category === "Region" ? "C" : "D",
          rank: pengurus[i].position_id ? pengurus[i].position_id.score : 0,
        });
      }

      result = result.sort((a,b) => a.rank - b.rank || a.sorting - b.sorting);

      this.setState({ executiveData: result });
    }
    else {
        console.log(resp);
        // if ( resp[1].data && resp[1].data[0].msg ) {
        //     setErrorText(resp[1].data[0].msg);
        // }
        // else {
        //     setErrorText(resp[1].message);
        // }
        // setOpenErrorAlert(true);
    }
    this.setState({ isLoading: false });
  }

  setActiveData(idData) {
    this.setState({
      open: false,
    });
  }

}

export default Executive;
