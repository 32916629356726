import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import Footer from "../Component/Footer_Com";
import Pembina from "../Component/Pembina_Com";
// import Pengurus from "../Component/Pengurus_Com";
import { getExecutiveBoards } from "../Logic/Share_Logic";
import "../Style/Executive_Style.css";

class ExecutiveDewan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pembinaData: [],
      // TODO: jika data pembina sudah ada maka hapus pembinaData di bawah lalu uncomment ini
      //   pembinaData: [
      //     {
      //       id: 0,
      //       foto: "/Assets/Executive/exe21.jpg",
      //       name: "Prof. Yenni Thamrin ",
      //       title: "Ketua Dewan Pembina",
      //       desc: "Ketua Umum Indonesian Diaspora Network - China",
      //     },
      //     {
      //       id: 1,
      //       foto: "/Assets/Executive/exe0.jpg",
      //       name: "Sultan B Najamudin",
      //       title: "Dewan Pembina",
      //       desc: "Wakil Ketua DPD RI, Komisaris ASA Karya Group",
      //     },
      //     {
      //       id: 2,
      //       foto: "/Assets/Executive/exe2.jpg",
      //       name: "Fachrul Razi M.I.P",
      //       title: "Penasehat",
      //       desc: "Wakil Ketua 1 Komite I DPD RI, Founder The Frazi Foundation",
      //     },
      //     {
      //       id: 3,
      //       foto: "/Assets/Executive/exe20.jpg",
      //       name: "Gugun Gumilar ",
      //       title: "Pengawas",
      //       desc: "Duta Muda PBB, Founder IDE Indonesia",
      //     },
      //   ],
      activeData: { id: 0, name: "", title: "", desc: "" },
      isLoading: true,
      open: true,
    };
  }
  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Mohon tunggu sebentar"
      >
        <Container fluid className="bg">
          <NavbarCenter />
          <Row className="bgImage" style={{ marginBottom: "3rem" }}>
            <Col sm={{ span: 10, offset: 1 }}>
              <Pembina data={this.state.pembinaData} />
              {/* <Pengurus data={this.state.executiveData} /> */}
            </Col>
          </Row>
          <Footer />
        </Container>
      </LoadingOverlay>
    );
  }

  componentDidMount() {
    this.setActiveData(0);
    this.getData();
  }

  async getData() {
    let resp = await getExecutiveBoards();
    if (resp[0] && resp[0].status === 200) {
      const pembina = resp[0].data.filter((res) => res.category === "dewan");
      // const pengurus = resp[0].data.filter(res => res.category === "pengurus");
      let result = [];
      // let pengurusRes = [];
      for (let i = pembina.length - 1; i >= 0; i--) {
        result.push({
          id: pembina.length - 1 - i,
          name: pembina[i].name ? pembina[i].name : pembina[i].member_id.name,
          jabatan: pembina[i].title,
          foto: pembina[i].image_executiveboard,
          rank: pembina[i].position_id ? pembina[i].position_id.score : 0,
        });
      }
      // for ( let i=pengurus.length-1; i >= 0; i-- ) {
      //   pengurusRes.push({
      //     id: (pengurus.length - 1) - i,
      //     nama: pengurus[i].member_id.name,
      //     cabang:
      //       pengurus[i].location_id.category === "Region" ?
      //         ("Pengurus Region - " + pengurus[i].location_id.name)
      //       : pengurus[i].location_id.category === "Chapter" ?
      //         ("Pengurus Chapter - " + pengurus[i].location_id.name)
      //       : ("Pengurus " + pengurus[i].location_id.name),
      //     jabatan: pengurus[i].title,
      //     foto: pengurus[i].image_executiveboard,
      //     sorting: pengurus[i].location_id.category === "Pusat" ? "A"
      //       : pengurus[i].location_id.category === "Luar Wilayah" ? "B"
      //       : pengurus[i].location_id.category === "Region" ? "C" : "D"
      //   });
      // }
      // pengurusRes = pengurusRes.sort((a,b) => a.sorting > b.sorting);
      result = result.sort((a,b) => a.rank - b.rank);
      this.setState({
        pembinaData: result,
        // executiveData: pengurusRes,
      });
    } else {
      console.log(resp);
      // if ( resp[1].data && resp[1].data[0].msg ) {
      //     setErrorText(resp[1].data[0].msg);
      // }
      // else {
      //     setErrorText(resp[1].message);
      // }
      // setOpenErrorAlert(true);
    }
    this.setState({ isLoading: false });
  }

  setActiveData(idData) {
    this.setState({
      activeData: this.state.pembinaData[idData],
      open: false,
    });
  }
}

export default ExecutiveDewan;
