import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Snackbar, TextField, CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import {
  getAllMembers,
  getCandidatesChairman,
  voteCandidateChairman,
} from "../Logic/Share_Logic";
import Footer from "../Component/Footer_Com";
import VotingCom from "../Component/Voting_Com";
import LoadingOverlay from "react-loading-overlay";
import "../Style/Executive_Style.css";
import "../Style/Voting_Style.css";
import "../Style/Modal_Style.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Voting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      isLoadSubmit: false,
      isOpenError: false,
      isOpenSuccess: false,
      errorText: "",
      successText: "",
      members: [],
      currMember: null,
      currMemberId: "",
      pembinaData: [],
      activeData: {
        id: 0,
        name: "",
        member_since: "",
        company_name: "",
        bussiness_fields: "",
        last_position: "",
        work_program: "",
        message: "",
        visi_misi: "",
      },
      open: true,
    };
    this.handleCloseToast = this.handleCloseToast.bind(this);
  }
  
  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ isLoading: true });
    let resp = await getCandidatesChairman();
    if ( resp[0] && resp[0].status === 200 ) {
      this.setState({
        pembinaData: resp[0].data,
        activeData: resp[0].data[0],
      });
      this.getAutocompleteData();
    }
    else {
      this.props.history.push("");
    }
  }
  async getAutocompleteData() {
    this.setState({ isLoading: false })
    let resp = await getAllMembers();
    if ( resp[0] && resp[0].status === 200 ) {
      let result = [];
      resp[0].data.forEach(res => {
        result.push({
          label: res.name,
          value: res.member_card_id,
        });
      });
      this.setState({
        members: result,
        isLoading: false,
      });
    }
    else {
      console.log(resp)
      this.setState({
        errorText: "Tidak dapat menemukan data member",
        isOpenError: true,
        isLoading: false,
      });
    }
  }
  setLoading(state) {
    this.setState({
      isLoading: state,
    });
  }
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  handleClose = (e) => {
    this.setState({ showModal: false });
  };
  handleCloseToast = () => {
    this.setState({ isOpenError: false, isOpenSuccess: false });
  }
  triggerModal = () => {
    this.setState({ showModal: true });
  };
  onChangeCandidate = (data) => {
    this.setState({ activeData: data });
  };
  onShowModal = async (event) => {
    event.preventDefault();
    if ( this.state.currMember ) {
      this.setState({ showModal: true });
    }
    else {
      this.setState({
        errorText: "Harap isi nama dan ID membership Anda",
        isOpenError: true,
      });
    }
  };
  onSubmitVote = async () => {
    this.setState({ isLoadSubmit: true });
    let resp = await voteCandidateChairman(
      this.state.activeData._id,
      this.state.currMember
    );
    if ( resp[0] && resp[0].status === 200 ) {
      this.setState({
        successText: "Berhasil melakukan voting!",
        isOpenSuccess: true,
        isLoadSubmit: false,
      });
      await this.sleep(1500);
      this.props.history.push("");
    }
    else if ( resp[0] && resp[0].status === 204 ) {
      this.setState({
        errorText: resp[0].message,
        isOpenError: true,
        isLoadSubmit: false,
      });
    }
    else {
      console.log(resp)
      this.setState({
        errorText: "Gagal melakukan vote.",
        isOpenError: true,
        isLoadSubmit: false,
      });
    }
  };
  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Mohon tunggu sebentar"
      >
        <Snackbar open={this.state.isOpenSuccess} autoHideDuration={2000} onClose={this.handleCloseToast}>
          <Alert severity="success">
            {this.state.successText}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.isOpenError} autoHideDuration={2000} onClose={this.handleCloseToast}>
          <Alert severity="error">
            {this.state.errorText}
          </Alert>
        </Snackbar>
        {this.state.currMember ?
          <Modal show={this.state.showModal} onHide={this.handleClose} className="voting-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                Apakah Anda yakin dengan pilihan Anda?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{`Nama : ${this.state.currMember.label}`}</p>
              <p>{`ID Membership : ${this.state.currMember.value}`}</p>
              <p>{`Pilihan Kandidat : ${this.state.activeData.member_id.name}`}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.onSubmitVote} className="submit-vote-btn">
                {this.state.isLoadSubmit?
                  <CircularProgress size={10} /> : "Yakin"
                }
              </Button>
              <Button variant="secondary" onClick={this.handleClose}>
                Belum
              </Button>
            </Modal.Footer>
          </Modal> : <></>
        }
        <Container fluid className="bg">
          <NavbarCenter />
          <Row className="bgImage">
            <Col sm={{ span: 10, offset: 1 }}>
              {this.state.pembinaData.length > 0?
                <Form onSubmit={this.onShowModal}>
                  {!this.state.isLoading && this.state.pembinaData.length > 0?
                    <VotingCom
                      data={this.state.pembinaData}
                      onChangeCandidate={(data) => this.onChangeCandidate(data)}
                    /> : <></>
                  }
                  <Row className="votingGroup">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label className="textLabel">Nama</Form.Label>
                        <Autocomplete
                          value={this.state.currMember}
                          options={this.state.members}
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => {
                            this.setState({
                              ...this.state,
                              currMember: data,
                              currMemberId: data? data.value : "",
                            });
                          }}
                          className="full-width dropdown-filter dropdown-voting"
                          renderInput={(params) =>
                            <TextField
                                {...params}
                                placeholder="Nama"
                                variant="filled"
                                className="input-filter"
                                required={false}
                                InputLabelProps={{ shrink: true }}
                            />}
                        />
                        {/* <Form.Control type="text" name="name" placeholder="Nama" /> */}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label className="textLabel">ID Membership</Form.Label>
                        <Form.Control
                          type="text"
                          name="idMembership"
                          value={this.state.currMemberId}
                          placeholder="xxxxxxxxxxxx"
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center buttonVoting votingGroup">
                    <Col xs lg="2"></Col>
                    <Col md="4" lg="3">
                      <Button
                        type="submit"
                        className="cardButton voting"
                      >
                        Vote
                      </Button>
                    </Col>
                    <Col xs lg="2"></Col>
                  </Row>
                </Form> : <></>
              }
            </Col>
          </Row>
          <Footer />
        </Container>
      </LoadingOverlay>
    );
  }
}

export default Voting;
