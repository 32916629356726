import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import { getSponsor, sponsorType } from "../Logic/Share_Logic";

class Sponsor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platinum: null,
      gold: null,
      silver: null,
      bronze: null,
    };
  }
  render() {
    return (
      <Container fluid className="bg">
        <NavbarCenter />

        <Row className="bgImage">
          <Col sm={{ span: 10, offset: 1 }}>
            {this.generateSponsor(this.state.platinum, 3, "platinum")}
            {this.generateSponsor(this.state.gold, 4, "gold")}
            {this.generateSponsor(this.state.silver, 5, "silver")}
            {this.generateSponsor(this.state.bronze, 6, "bronze")}
          </Col>
        </Row>
        <br />
        <Footer />
      </Container>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    var sponsor = await getSponsor();
    var bronze = await sponsorType(sponsor[1], "level", "Bronze");
    var silver = await sponsorType(sponsor[1], "level", "Silver");
    var gold = await sponsorType(sponsor[1], "level", "Gold");
    var platinum = await sponsorType(sponsor[1], "level", "Platinum");

    this.setState({
      bronze: bronze,
      silver: silver,
      gold: gold,
      platinum: platinum,
    });
  }

  generateSponsor(arr, col, colStyleClass) {
    var card = [];
    if (arr == null) {
      return null;
    }
    arr.map((data) => {
      card.push(
        <Card.Img variant="top" src={data} className={colStyleClass} />
      );
      return "";
    });
    var carouselItem = [];
    var cardDeck = [];
    for (var i = 0; i < card.length; i++) {
      if (i % parseInt(col) === parseInt(col) - 1) {
        cardDeck.push(card[i]);
        carouselItem.push(<Row className="sponsorList">{cardDeck}</Row>);
        cardDeck = [];
      } else {
        cardDeck.push(card[i]);
      }
      if (i === card.length - 1 && cardDeck.length > 0) {
        carouselItem.push(<Row className="sponsorList">{cardDeck}</Row>);
        cardDeck = [];
      }
    }
    return carouselItem;
  }
}

export default Sponsor;
