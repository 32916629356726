import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import "../Style/Default_Style.css";
import "../Style/Event_Style.css";
import Gallery from "../Component/Gallery_Com";
import { getSpecificEvent } from "../Logic/Share_Logic";
import moment from "moment";
// import queryString from "query-string";

class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: null
    };
  }
  render() {
    return (
      <Container fluid className="bg">
        <NavbarCenter />
        <Row
          style={{
            backgroundImage: 'url("/Assets/bg.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%"
          }}
        >

          <Col lg={2} md={1}></Col>
          {this.generateEventData()}
          <Col lg={2} md={1}></Col>
        </Row>
        <Footer />
      </Container>
    );
  }

  componentDidMount() {
    let idLocale = require("moment/locale/id");
    moment.locale("id", idLocale);
    this.fetchEventData();
  }

  async fetchEventData() {
    let resp = await getSpecificEvent(this.props.match.params.id);
    if ( resp[0] && resp[0].status === 200 ) {
      this.setState({ eventData: resp[0].data });
    }
    else {
      console.log(resp);
    }
    
  }

  generateEventData() {
    if (this.state.eventData) {
      return (
        <Col lg={8} md={10} sm={12} style={{marginTop:"3rem", minHeight:"450px"}}>
          <Row className="eventDetailContainer">
            <Col sm={6}>
              <Image
                className="animation homeEventImage"
                fluid
                src={this.state.eventData.thumbnail}
              />
            </Col>
            <Col sm={6} className="event-detail-info">
              <h5 className="title animation">
                {this.state.eventData.name}
              </h5>
              <h5 className="title sub animation">
                {this.state.eventData.event_categories.name}
              </h5>
              <p className="pembinaDesc animation">
                {moment(this.state.eventData.date).format("DD MMMM YYYY")}
              </p>
              <p className="pembinaDesc animation">
                {this.state.eventData.address}
              </p>
              <Link
                to={{ pathname: this.state.eventData.address_link }}
                className="event-link"
                target="_blank"
              >
                Link Alamat
              </Link>
              <p className="pembinaDesc animation"></p>
              {this.state.eventData.pic ?
                <>
                  <p className="pembinaDesc animation">Contact Person :</p>
                  <div style={{ marginLeft: 20 }}>
                    <p className="pembinaDesc animation" style={{ marginBottom: 5 }}>
                      {this.state.eventData.pic}
                    </p>
                    <p className="pembinaDesc animation">
                      {this.state.eventData.pic_contact}
                    </p>
                  </div>
                </> : <></>
              }
            </Col>
          </Row>
          <Row className="event-desc-container">
            <Col sm={1} md={12}></Col>
            <Col sm={10} md={12}>
              <p className="pembinaDesc animation">
                {this.state.eventData.report?
                  this.state.eventData.report : this.state.eventData.description
                }
              </p>
            </Col>
            <Col sm={1} md={12}></Col>
          </Row>
          <Gallery imageBatch={this.state.eventData.gallery} />
        </Col>
      )
    }
  }
}

export default EventDetail;
