import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import Fade from 'react-reveal/Fade';
import { Container, Row, Col, Image} from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import "../Style/AboutUs_Style.css";

class About extends Component {
  render() {
  window.scrollTo(0,0);
    return (
      <Container fluid className='bg'>
        <NavbarCenter />
        <Row className="about">
          <Col className='aboutText'>
            <Fade duration={3000}>
              <p className='aboutText2'>
                <b>Idepreneurs Club</b> adalah organisasi yang didirikan di tahun 2016 oleh sekelompok pemuda
                yang mempunyai visi dan misi yang seiring dengan perkembangan Pemerintah Indonesia yang
                terus berusaha mengenjot pertumbuhan wirausaha agar dapat menjadi seperti negara maju yang pertumbuhan
                ekonominya dimotori oleh wiraswasta. Berbagai cara dilakukan untuk membentuk pikiran masyarakat agar menjadi
                pengusaha dan tidak hanya menjadi pencari kerja.
              </p>
            </Fade> 
          </Col>
        </Row>
        <Row className="aboutImage">
          <Col sm={{ span: 10, offset: 1 }}>
            <Row>
              <Col className="pastEvent w-100">
                  <Row>               
                    <Col lg={4} md={6} sm={6}>
                      <Fade duration={1000} >
                        <Image fluid src="/Assets/visi.png" className="vision" />
                      </Fade>
                    </Col>                   
                    <Col lg={8} md={6} sm={6}> 
                    <Fade right cascade duration={1000}>                        
                      <h3 className="judul">Visi</h3>                     
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Indonesia Sejahtera</p>
                        </div>
                      </div>                  
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <h3 className='judul'>Misi</h3>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Menghimpun calon pengusaha</p>
                        </div>
                      </div>
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Mengembangkan usaha yang ada agar dapat bersaing di skala nasional/ internasional</p>
                        </div>
                      </div>
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Membimbing, mendampingi & turut serta dalam merealisasikan ide usaha</p>
                        </div>
                      </div>
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Membangun jaringan sinergi & strategis antar anggota atau antar lembaga</p>
                        </div>
                      </div>
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Memghimpun, mengelola dan mendistribusikan Informasi yang dapat  membangun/ mengembangkan usaha anggota</p>
                        </div>
                      </div>
                    </Fade>
                    <Fade right cascade duration={1000}>
                      <div className="visiDanMisi">
                        <div className="listImage">
                          <Image src='/Assets/list.png' alt='tes' className='list' />
                        </div>
                        <div className="listText">
                          <p className="tanggal">Berpartisipasi dalam perkembangan roda perekonomian Indonesia</p>
                        </div>
                      </div>                      
                    </Fade>                                                                 
                    </Col>
                  </Row>
                {/* </Fade> */}
                  <Row style={{ paddingTop: 50 }}>
                    <Col lg={8} md={6} sm={6}>
                      <Fade left duration={1000} cascade>
                        <h3 className='judul'>One Million Benefit</h3>
                      </Fade>
                      <Row>
                        <Col>
                          <Fade left duration={1000} cascade>
                            <div>
                              <p className='millionHeader'>
                                <Image src='/Assets/list.png' alt='tes' className='list' />Gerakan Sejuta Pengusaha Idepreneurs
                              </p>
                              <p className='millionContent'>
                                Idepreneurs akan menghimpun sejuta wiraswasta muda untuk Indonesia
                              </p>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Fade left duration={1000} cascade>
                            <div>
                              <p className='millionHeader'><Image src='/Assets/list.png' alt='tes' className='list' />
                                Gerakan Sejuta Usaha Idepreneurs 
                              </p >
                              <p className='millionContent'>
                                Idepreneurs ingin menciptakan atau mengembangkan sejuta usaha karya putra putri Indonesia
                              </p>
                            </div>
                          </Fade>                            
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Fade left duration={1000} cascade>
                            <div>
                              <p className='millionHeader'><Image src='/Assets/list.png' alt='tes' className='list' />
                                Satu Kartu Sejuta Manfaat
                              </p>
                              <p className='millionContent'>
                                Anggota Idepreneurs dapat menikmati keuntungan lebih dari satu juta rekanan / merchant dengan menunjukan kartu anggota atau yang dikenal dengan "one card millions benefit"
                              </p>
                            </div>
                          </Fade>                         
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      <Fade duration={1000}>
                        <Image fluid src="/Assets/misi.png" className="mision" />
                      </Fade>                     
                    </Col>
                  </Row>
              </Col>
            </Row >
          </Col>
        </Row>
        <Footer />
      </Container >
    );
  }
}

export default About;
