import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../Style/Executive_Style.css";
import PengurusCard from "../Component/PengurusCard_Com";
class Pengurus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeData: { id: 0, name: "", title: "", desc: "" },
    };
  }
  render() {
    return <>{this.generatePengurus(this.props.data)}</>;
  }
  
  generatePengurus = (data) => {
    var pengurusCabang = [];
    var pengurusList = [];
    for (var i = 0; i < data.length - 1; i = i + 1) {
      if (i === data.length - 2) {
        if (data[i].cabang === data[i + 1].cabang) {
          pengurusCabang.push(data[i]);
          pengurusCabang.push(data[i + 1]);
          pengurusList.push(
            <>
              <Row className="title">
                <Col>
                  <h3 className="judul">{data[i].cabang}</h3>
                </Col>
              </Row>
              <>{this.generatePengurusChapter(pengurusCabang)}</>
            </>
          );
        }
        else {
          pengurusCabang.push(data[i]);
          pengurusList.push(
            <>
              <Row className="title">
                <Col>
                  <h3 className="judul">{data[i].cabang}</h3>
                </Col>
              </Row>
              <>{this.generatePengurusChapter(pengurusCabang)}</>
            </>
          );

          pengurusCabang = [];
          pengurusCabang.push(data[i+1]);
          pengurusList.push(
            <>
              <Row className="title">
                <Col>
                  <h3 className="judul">{data[i+1].cabang}</h3>
                </Col>
              </Row>
              <>{this.generatePengurusChapter(pengurusCabang)}</>
            </>
          );
        }
      }
      else if (data[i].cabang === data[i + 1].cabang) {
        pengurusCabang.push(data[i]);
      }
      else {
        pengurusCabang.push(data[i]);
        pengurusList.push(
          <>
            <Row className="title">
              <Col>
                <h3 className="judul">{data[i].cabang}</h3>
              </Col>
            </Row>
            <>{this.generatePengurusChapter(pengurusCabang)}</>
          </>
        );
        pengurusCabang = [];
      }
    }
    return pengurusList;
  };

  generatePengurusChapter = (data) => {
    var pengurusRow = [];
    var pengurusList = [];
    for (var i = 0; i < data.length; i = i + 1) {
      if (i === data.length - 1) {
        pengurusRow.push(
          <Col key={data[i].id} md={3} xs={6}>
            <PengurusCard content={data[i]} key={data[i].id} />
          </Col>
        );
        pengurusList.push(<Row>{pengurusRow}</Row>);
        pengurusRow = [];
      } else if (i > 0 && i % 4 === 3) {
        pengurusRow.push(
          <Col key={data[i].id} md={3} xs={6}>
            <PengurusCard content={data[i]} key={data[i].id} />
          </Col>
        );
        pengurusList.push(<Row>{pengurusRow}</Row>);
        pengurusRow = [];
      } else {
        pengurusRow.push(
          <Col key={data[i].id} md={3} xs={6}>
            <PengurusCard content={data[i]} key={data[i].id} />
          </Col>
        );
      }
    }
    return pengurusList;
  };
}

export default Pengurus;
