import React, { Component } from "react";
import FsLightbox from 'fslightbox-react'; 
import { Container,  Row, Col } from "react-bootstrap";

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
      title: this.props.title,
      address: this.props.address,
      date: this.props.date,
      description: this.props.description
    };
  }
  state={
    isVisible: false,
    slide: 0,
  }
  showSlide = (slide) => {
    this.setState({
      isVisible: !this.state.isVisible,
      slide: slide
    });
  }

  generateImage = (dataArr) =>{
    var tmp = [];
    if(dataArr){
      dataArr.forEach((data,i) => {
        tmp.push(
          <Col key={`event-gal-${i}`} xs={4} className="eventImage-col">
              <img
                className ="eventImage"
                onClick={() => this.showSlide(i + 1)}
                src={data}
                width="100%"
                alt={`gallery-${i}`}
              />      
            </Col>
        );
      })
      return tmp
    }
    
  }
  render() {
    if(this.props.imageBatch){
      return (
        <Container className='galleryContainer'>
          <Row className='pengurusRow'>
            <FsLightbox
              toggler={this.state.isVisible}
              slide={this.state.slide}
              sources={this.props.imageBatch}
              type="image" 
            />
            {this.generateImage(this.props.imageBatch)}
          </Row>
        </Container>
      );
    }else{
      return <main></main>
    }
    
  }
}

export default Gallery;
