import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import "../Style/Navigation_Style.css"

class NavbarCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClick: false,
    };
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        id="navbar-scroll"
        className="justify-content-center"
        expand="lg"
        style={{background:"#343a40"}}
        variant="dark"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="burgerButton"/>
        <Navbar.Brand href="/"
          className="logoMobile navbar-title"
        >
          IDEPRENEURS
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav" className="collapseBG ">
          <Nav className="mr-20 ml-auto">
            {/* <Nav.Link href="/OurProgram" className="navShrink navMenu mr-3 ml-3" >Kegiatan</Nav.Link> */}
            <NavDropdown className="mr-3 ml-3  navMenu navShrink" title="Kegiatan" id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/OurProgram-Upcoming" className=" navMenu">Kegiatan Mendatang</NavDropdown.Item>
              <NavDropdown.Item href="/OurProgram" className=" navMenu">Kegiatan Lampau</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="mr-3 ml-3  navMenu navShrink" title="Mitra Kami" id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/Merchant" className=" navMenu">Merchant Benefit</NavDropdown.Item>
              <NavDropdown.Item href="/Partner" className=" navMenu">Partner</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="mr-3 ml-3  navMenu navShrink" title="Organisasi" id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/Executive-Dewan" className=" navMenu">Pembina</NavDropdown.Item>
              <NavDropdown.Item href="/Executive" className=" navMenu">Pengurus</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Navbar.Brand href="/" className="logoDesktop text-white mr-3 ml-3" >
            <Image
              src="/Assets/logo.svg"
              width="45"
              height="45"
              alt="idepreneurs" />
            {/* <Image className="arrow up"
              width="45"
              height="45"
            /> */}
          </Navbar.Brand>
          <Nav className="mr-auto ml-20">
          <Nav.Link href="/Contact" className=" mr-3 ml-3  navMenu navShrink">Hubungi Kami</Nav.Link>
            <Nav.Link href="/About" className=" mr-3 ml-3  navMenu navShrink">Tentang Kami</Nav.Link>
            <NavDropdown className="mr-3 ml-3 navMenu navShrink" title="Pendaftaran" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/Register"  className="navMenu">Pendaftaran Member</NavDropdown.Item>
              <NavDropdown.Item href="/RegisterMerchant"  className=" menuDropDown navMenu">Pendaftaran Merchant</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    );
  }


  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (parseInt(window.innerWidth) > parseInt(1000)) {
      this.setState({ isClick: false });
    } else {
      this.setState({ isClick: true });
    }
  }

  resizeHeaderOnScroll() {
    let height = window.outerHeight || document.documentElement.clientHeight
    let width = window.outerWidth || document.documentElement.clientWidth
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 10,
      headerEl = document.getElementById("navbar-scroll");

      if(width > height){
        if (distanceY > shrinkOn) {
          headerEl.classList.add("smaller");
        } else {
          headerEl.classList.remove("smaller");
        }
      }
  }
}

export default NavbarCenter;
