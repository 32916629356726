import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Card } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Footer from "../Component/Footer_Com";
import { getMerchants } from "../Logic/Share_Logic";
import "../Style/Mitra_Styles.css";

class Merchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: null,
    };
  }
  render() {
    return (
      <Grid container className="bg">
        <NavbarCenter />

        <Grid item xs={12} className="bgImage">
          <Grid container className="mitra-content">
            <Grid item xs={12} className="title">
              <h3 className="judul">Merchant Benefit</h3>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {this.generateMerchant(this.state.merchant, 3, "merchant")}
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let resp = await getMerchants();
    if ( resp[0] && resp[0].status === 200 ) {
      let result = [];
      const merchants = resp[0].data;
      merchants.forEach(res => {
        if ( res.status === "active" ) {
          result.push(res);
        }
      });
      this.setState({
        merchant: result,
      });
    }
    else {
      console.log(resp);
    }
  }

  generateMerchant(arr, col, colStyleClass) {
    var card = [];
    if (arr == null) {
      return (
        <div
          className="merchantNull"
          style={{
            height: window.outerHeight * 0.75,
          }}
        >
          Sedang tidak ada Promo
        </div>
      );
    }
    arr.map((data) => {
      card.push(
        <Card.Img variant="top" src={data.logo} className={colStyleClass} />
      );
      return "";
    });
    var carouselItem = [];
    // var cardDeck = [];
    for (var i = 0; i < card.length; i++) {
      carouselItem.push(
        <Grid item xs={6} sm={4} md={3}>
          {card[i]}
        </Grid>
      );
      // if (i % parseInt(col) === parseInt(col) - 1) {
      //   cardDeck.push(card[i]);
      //   carouselItem.push(<Row className="sponsorList">{cardDeck}</Row>);
      //   cardDeck = [];
      // } else {
      //   cardDeck.push(card[i]);
      // }
      // if (i === card.length - 1 && cardDeck.length > 0) {
      //   carouselItem.push(<Row className="sponsorList">{cardDeck}</Row>);
      //   cardDeck = [];
      // }
    }
    return carouselItem;
  }
}

export default Merchant;
