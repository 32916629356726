import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../Style/Executive_Style.css";
class Pembina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: this.props.data[0],
    };
  }
  render() {
    return (
      this.props.data && this.props.data.length > 0 ?
      <>
        <Row className="title">
          <Col>
            <h3 className="judul">Dewan Pembina Organisasi</h3>
          </Col>
        </Row>
        <Row>{this.generatePembina(this.props.data)}</Row>
        <Row className="pembinaContent">
          <Col>
            <h3 className="namaPembina">
              { this.state.activeData ? this.state.activeData.name
                : !this.state.activeData ? this.props.data[0].name
                : ""
              }
            </h3>
            <h5 className="jabatan">
              { this.state.activeData ? this.state.activeData.jabatan
                : !this.state.activeData ? this.props.data[0].jabatan
                : ""
              }
            </h5>
            {/* <p className="pembinaDesc">{this.state.desc}</p> */}
          </Col>
        </Row>
      </> : <></>
    );
  }
  componentDidMount() {
    this.setActiveData(0);
  }

  setActiveData(idData) {
    console.log(this.state.activeData)
    this.setState({
      activeData: this.props.data[idData],
      open: false,
    });
  }
  generatePembina = (data) => {
    var pembinaList = [];
    data.map((pembina) => {
      pembinaList.push(
        <Col
          sm={3}
          xs={6}
          onClick={() => this.setActiveData(pembina.id)}
          key={pembina.id}
        >
          <div className={"pembinaWrap"}>
            <Image
              src={pembina.foto}
              width="100%"
              className={
                this.state.activeData && this.state.activeData.id === pembina.id
                ? "pembinaActive"
                : !this.state.activeData && pembina.id === 0
                ? "pembinaActive"
                : "pembina"
              }
            />
          </div>
        </Col>
      );
      return "";
    });
    return pembinaList;
  };
}

export default Pembina;
