import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ModalImage from "react-modal-image";
import MuiAlert from '@material-ui/lab/Alert';
import Spinner from '../Component/Loading_Com';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NavbarCenter from "../Component/NavigationBar_Com";
import '../Style/Form_Style.css';
import city from '../Data/data';
import { registerMember } from '../Logic/Share_Logic';
import {
    Button, FormControlLabel, Grid,
    Radio, Checkbox, Snackbar, TextField,
} from '@material-ui/core';
import {
    TextInput, EmailInput, PhoneInput,
    DateInput, ImageInput, GroupedDropdown,
    RadioButtonInput, ChoiceboxInput,
} from '../Logic/FormInput';
import moment from 'moment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMember() {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [cityData, setCityData] = useState([]);
    const [gender, setGender] = useState('male');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedDate, setDate] = useState(moment());
    const [selectedBirthPlace, setSelectedBirthPlace] = useState(null);
    const [selectedDomicile, setSelectedDomicile] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);

    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [showErrorBirth, setShowErrorBirth] = useState(false);
    const [showErrorDomicile, setShowErrorDomicile] = useState(false);

    // Method that run first when the page is opened 
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        let res = [];
        city.forEach(val => {
            res.push({
                name: val, value: val,
            });
        });
        setCityData(res);
        setIsLoading(false);
    }, []);

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = () => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
    };

    // Handle (gender) radio button event
    const onRBChange = (event) => {
        setGender(event.target.value);
    }

    // Handle when date is changed
    const handleDateChange = (date) => {
        setDate(moment(date));
    };

    // Handle onChange phone number & prevent user to input characters
    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    setPhoneNumber(event.target.value);
                }
            }
            // only allow numbers after first input
            else {
                if ( numberRegex.test(value.substring(1)) ) {
                    setPhoneNumber(event.target.value);
                }
            }
        }
        else {
            setPhoneNumber(event.target.value);
        }
    }

    // Handle onChange autocomplete
    const handlePlaceOfBirth = (_, values) => {
        setSelectedBirthPlace(values);
        setShowErrorBirth(false);
    }
    const handleDomicile = (_, values) => {
        setSelectedDomicile(values);
        setShowErrorDomicile(false);
    }
    const handleClothesSize = (_, values) => {
        setSelectedSize(values);
    }

    // Send new member data to server
    const onSubmit = async (data) => {
        if ( !selectedBirthPlace || !selectedDomicile ) {
            if (!selectedBirthPlace) setShowErrorBirth(true);
            if (!selectedDomicile) setShowErrorDomicile(true);
        }
        else {
            // if ( !event.nativeEvent.submitter || !event.nativeEvent.submitter.className.includes('delete-btn') ) {
                setIsLoading(true);
                data["clothes_size"] = selectedSize;
                let resp = await registerMember(data, selectedBirthPlace.value, selectedDomicile.value);
                if (resp[0]) {
                    setOpenSuccessAlert(true);
                    await sleep(1000);
                    history.go(0);
                }
                else {
                    if ( resp[1] ) {
                        if ( resp[1].data && resp[1].data[0].msg ) {
                            setErrorText(resp[1].data[0].msg);
                        }
                        else {
                            setErrorText(resp[1].message);
                        }
                    }
                    else {
                        setErrorText("Gagal melakukan registrasi. Silakan coba hubungi admin.");
                    }
                    setOpenErrorAlert(true);
                    setIsLoading(false);
                }
            // }
        }
    }

    return (
        <>
            <NavbarCenter />
            <div className="content form" style={{ backgroundColor: "#505050" }}>
                { isLoading ? ( <Spinner /> ) : "" }

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Melakukan Registrasi
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            
                            <Grid container>
                                <Grid item xs={12}>
                                    <h4 className="form-title">Form Pendaftaran</h4>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for name */}
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for membership */}
                                    <GroupedDropdown
                                        label="Jenis Keanggotaan"
                                        labelPosition="half-right-label"
                                        name="membership"
                                        className="form-right-field add-margin-bottom grouped-field"
                                        control={control}
                                        options={[
                                            { label: "Silver", value: "silver" },
                                            { label: "Platinum", value: "platinum" },
                                        ]}
                                        defaultValue={ history.location.state &&
                                            history.location.state.subscriptionType === "Platinum" ? 
                                                { label: "Platinum", value: "platinum" }
                                                :
                                                { label: "Silver", value: "silver" }
                                        }
                                        isClearable={false}
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for job */}
                            <TextInput
                                label="Bidang Usaha *"
                                name="job"
                                placeholder="Bidang Usaha"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*bidang usaha harus diisi" })}
                                errors={errors.job}
                                errorClassName="text-required"
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for place of birth */}
                                    <Autocomplete
                                        options={cityData}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handlePlaceOfBirth}
                                        value={selectedBirthPlace}
                                        className="form-left-field tf-label add-margin-bottom"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Tempat Lahir *"
                                                variant="outlined"
                                                placeholder="Pilih Kota"
                                                InputLabelProps={{ shrink: true }}
                                            />}
                                    />
                                    {showErrorBirth? <p className="text-required-half">*tempat lahir harus diisi</p> : <></>}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    {/* Input for date of birth */}
                                    <DateInput
                                        label="Tanggal Lahir"
                                        name="date_of_birth"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        className="form-right-field"
                                        validation={register({ register: "*tanggal lahir harus diisi" })}
                                        errors={errors.date_of_birth}
                                        errorClassName="text-required-half half-right"
                                        disableFuture={true}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ display: selectedBirthPlace &&
                                    selectedBirthPlace.value === "Lainnya"? "block" : "none" }}
                                >
                                    {/* Free text input for place of birth */}
                                    <TextInput
                                        label="Nama Tempat Lahir *"
                                        name="place_of_birth"
                                        placeholder="Nama"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: 
                                            selectedBirthPlace && selectedBirthPlace.value === "Lainnya"?
                                                "*nama kota harus diisi" : false
                                        })}
                                        errors={errors.place_of_birth}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            <TextInput
                                label="Alamat Domisili *"
                                name="address"
                                placeholder="Alamat Domisili"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*alamat domisili harus diisi" })}
                                errors={errors.address}
                                errorClassName="text-required"
                            />
                            <TextInput
                                label="Alamat Pengiriman *"
                                name="shipping_address"
                                placeholder="Alamat Pengiriman"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*alamat pengiriman harus diisi" })}
                                errors={errors.shipping_address}
                                errorClassName="text-required"
                            />

                            {/* Input for gender */}
                            <RadioButtonInput
                                inputLabel="Jenis Kelamin *"
                                control={control}
                                name="gender"
                                rules={{ required: "*jenis kelamin harus diisi" }}
                                errors={errors.gender}
                                errorClassName="text-required"
                                onRBChange={onRBChange}
                                options={
                                    <Grid container className="rb-layout">
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio />}
                                                label="Laki-laki"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio />}
                                                label="Perempuan"
                                                style={{ marginLeft: "0" }}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for phone number */}
                                    <PhoneInput
                                        label="Nomor Kontak *"
                                        name="phone_number"
                                        placeholder="Nomor Kontak"
                                        className="form-left-field"
                                        value={phoneNumber}
                                        onChange={onChangePhoneNumber}
                                        validation={register}
                                        errors={errors.phone_number}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for email */}
                                    <EmailInput
                                        label="Email *"
                                        name="email"
                                        placeholder="Email"
                                        className="form-right-field"
                                        validation={register}
                                        errors={errors.email}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for domisili */}
                                    <Autocomplete
                                        options={cityData}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleDomicile}
                                        value={selectedDomicile}
                                        className="full-width tf-label add-margin-bottom"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Kota Domisili *"
                                                variant="outlined"
                                                placeholder="Pilih Kota"
                                                InputLabelProps={{ shrink: true }}
                                            />}
                                    />
                                    {showErrorDomicile? <p className="text-required">*domisili harus diisi</p> : <></>}
                                </Grid>
                                <Grid item xs={12} style={{ display: selectedDomicile &&
                                    selectedDomicile.value === "Lainnya"? "block" : "none" }}
                                >
                                    {/* Free text input for place of birth */}
                                    <TextInput
                                        label="Nama Domisili *"
                                        name="city"
                                        placeholder="Nama Domisili"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: 
                                            selectedDomicile && selectedDomicile.value === "Lainnya"?
                                                "*nama domisili harus diisi" : false
                                        })}
                                        errors={errors.city}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for join reason */}
                            <ChoiceboxInput
                                inputLabel="Alasan Bergabung"
                                options={
                                    <Grid container className="cb-layout">
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                value="Menambah Networking"
                                                control={<Checkbox/>}
                                                label="Menambah Networking"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Menambah Konsumen Potensial"
                                                control={<Checkbox/>}
                                                label="Menambah Konsumen Potensial"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Mengembangkan Usaha"
                                                control={<Checkbox/>}
                                                label="Mengembangkan Usaha"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingLeft: "20px" }}>
                                            <FormControlLabel
                                                value="Menambah Suplier Potensial"
                                                control={<Checkbox/>}
                                                label="Menambah Suplier Potensial"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Mencari Rekan Usaha"
                                                control={<Checkbox/>}
                                                label="Mencari Rekan Usaha"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for reference */}
                                    <GroupedDropdown
                                        label="Referensi *"
                                        name="reference"
                                        className="form-left-field add-margin-bottom grouped-field"
                                        rules={{ required: "*referensi harus diisi" }}
                                        control={control}
                                        options={[
                                            { label: "Anggota", value: "member" },
                                            { label: "Media Sosial", value: "social-media" },
                                            { label: "Lainnya", value: "other" },
                                        ]}
                                        isClearable={true}
                                        defaultValue=""
                                        errors={errors.reference}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for reference source */}
                                    <TextInput
                                        label="Sumber Referensi (Nama Anggota/Sosmed/Lainnya) *"
                                        name="reference_source"
                                        placeholder="Sumber Referensi"
                                        className="form-right-field"
                                        validation={register({ required: "*sumber referensi harus diisi" })}
                                        errors={errors.reference_source}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for domisili */}
                                    <Autocomplete
                                        options={[
                                            { label: "XS", value: "XS" },
                                            { label: "S", value: "S" },
                                            { label: "M", value: "M" },
                                            { label: "L", value: "L" },
                                            { label: "XL", value: "XL" },
                                            { label: "XXL", value: "XXL" },
                                            { label: "3XL", value: "3XL" },
                                        ]}
                                        getOptionLabel={(option) => option.label}
                                        onChange={handleClothesSize}
                                        value={selectedSize}
                                        className="full-width tf-label add-margin-bottom"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Ukuran Baju *"
                                                variant="outlined"
                                                placeholder="Pilih Ukuran"
                                                InputLabelProps={{ shrink: true }}
                                            />}
                                    />
                                    {showErrorDomicile? <p className="text-required">*ukuran baju harus diisi</p> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <ModalImage
                                    small={`/Assets/clothes-size-reference.jpg`}
                                    large={`/Assets/clothes-size-reference.jpg`}
                                    hideDownload={true}
                                    className='clothes-image'
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: "20px" }}>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="national_identification"
                                        label="Foto KTP *"
                                        validation={register}
                                        errors={errors.national_identification}
                                        isEdit={false}
                                        control={control}
                                        error_label="* foto KTP"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="photo"
                                        label="Foto *"
                                        validation={register}
                                        errors={errors.photo}
                                        isEdit={false}
                                        control={control}
                                        error_label="* foto"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="proof_of_payment"
                                        label="Bukti Bayar *"
                                        validation={register}
                                        errors={errors.proof_of_payment}
                                        isEdit={false}
                                        control={control}
                                        error_label="* bukti bayar"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn member"
                            >DAFTAR</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}