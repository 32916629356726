import React from "react";
import "./App.css";
import { BrowserRouter as Router,  Route } from "react-router-dom";
import Home from "./Page/Home_Page";
// import EventList from "./Page/EventList_Page";
import Partner from "./Page/Partner_Page";
import Executive from "./Page/Executive_Page";
import ExecutiveDewan from "./Page/ExecutiveDewan_Page";
import Contact from "./Page/Contact_Page";
import About from "./Page/About_Page";
import RegisterV2 from "./Page/Register_Page_V2";
// import RegisterForm from "./Page/FormRegister_Page";
import RegisterFormV2 from "./Page/RegisterHookForm_Page";
import Maintenance from "./Page/Maintenance_Page";
import Sponsor from "./Page/Sponsor_Page";
import OurProgram from "./Page/OurProgram_Page";
import OurProgramUpcoming from "./Page/OurProgramUpcoming_Page";
import EventDetail from "./Page/EventDetail_Page";
import Merchant from "./Page/Merchant_Page";
// import RegisterMerchant from "./Page/RegisterMerchant_Page";
import RegisterMerchant from "./Page/RegisterMerchantHookForm_Page";
import Voting from "./Page/Voting_Page";
import Policy from "./Page/Policy_Page";
// import Webinar from "./Page/Webinar_Page";

function App() {
  return (
    <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/OurProgram/:param?" component={OurProgram} />
        <Route exact path="/OurProgram-Upcoming/:param?" component={OurProgramUpcoming} />
        <Route exact path="/ProgramDetail/:id" component={EventDetail} />
        {/* <Route exact path="/Event" component={EventList} /> */}
        {/* <Route exact path="/EventDetail/:id" component={EventDetail} /> */}
        <Route exact path="/Partner" component={Partner} />
        <Route exact path="/Executive" component={Executive} />
        <Route exact path="/Executive-Dewan" component={ExecutiveDewan} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Policy" component={Policy} />
        <Route exact path="/Register" component={RegisterV2} />
        {/* <Route exact path="/RegisterForm" component={RegisterForm} /> */}
        <Route exact path="/RegisterForm" component={RegisterFormV2} />
        <Route exact path="/Maintenance" component={Maintenance} />
        <Route exact path="/Sponsor" component={Sponsor} />
        <Route exact path="/Merchant" component={Merchant} />
        <Route exact path="/RegisterMerchant" component={RegisterMerchant} />
        <Route exact path="/Voting" component={Voting} />
        {/* <Route exact path="/Webinar" component={Webinar} /> */}
    </Router>
  );
}

export default App;
