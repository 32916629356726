import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Grid, TextField, Card, CardContent, CardMedia, Typography, makeStyles }
from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import TruncateMarkup from "react-truncate-markup";
import moment from "moment";
import "../Style_v2/EventHome_Style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 250,
    backgroundPosition: "bottom center",
    transition: "all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)",
  },
  card: {
    background: "transparent",
  },
  CardContent: {
    background: "lightgrey",
    color: "black",
    paddingTop: 0,
  },
}));

export default function EventHome(props) {

  const classes = useStyles();
  const history = useHistory();
  const readMoreEllipsis = <span>... </span>;

  useEffect(() => {
    // Set Indonesia as moment's location
    let idLocale = require("moment/locale/id");
    moment.locale("id", idLocale);
  }, []);

  return (
    // <div className={classes.root}>
      <Grid container className="event-container" spacing={2}>
        {props.eventData && props.eventData.map((data) => (
          <Grid item xs={12} md={6} lg={4} className="card-event" key={data._id}>
            <Card
              className={classes.card}
              style={{ height: "100%" }}
              onClick={() => history.push("/ProgramDetail/" + data._id)}
            >
              <CardMedia
                className={classes.media + " card-media"}
                image={data.banner}
              />
              <CardContent className={classes.CardContent + " card-content"}>
                <Grid container className="card-content-event">
                  <Grid item xs={3}>
                    <div className="date-mask">
                      <p>{moment(data.date).format("DD")}</p>
                      <p>{moment(data.date).format("MMM")}</p>
                      <p>{moment(data.date).format("YYYY")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <div className="card-text-container">
                      <Typography gutterBottom component="h5">
                        {data.name}
                      </Typography>
                      <Typography gutterBottom component="p">
                        {data.address}
                      </Typography>
                      <Typography gutterBottom component="p">
                        Pukul : {moment(data.date).format("HH:mm")} WIB
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TruncateMarkup lines={2} ellipsis={readMoreEllipsis}>
                      <Typography
                        gutterBottom
                        varian="p"
                        component="p"
                        className="card-title-event"
                      >
                        {data.description}
                      </Typography>
                    </TruncateMarkup>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
        { props.eventData && props.eventData.length <= 0 ?
          <h3
            className="judul"
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            Kegiatan tidak ditemukan!
          </h3>
          : <></>
        }
        { props.isUpcoming || props.totalPage <= 1 ? <></> :
          <Grid item xs={12}>
            <div className="custom-pagination">
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                className={`pagination-arrow ${props.currPage == 1?
                  "disabled" : ""}`}
                style={{ marginRight: "20px" }}
                onClick={() => props.handlePagination('prev')}
                size="3x"
              />
              <TextField
                variant="filled"
                className="pagination-input"
                value={props.counter}
                onChange={(e) => props.onChangePagination(e)}
                onBlur={props.onBlurPagination}
              />
              <span className="total-page">
                / {props.totalPage}
              </span>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                className={`pagination-arrow ${props.currPage == props.totalPage?
                  "disabled" : ""}`}
                style={{ marginLeft: "20px" }}
                onClick={() => props.handlePagination('next')}
                size="3x"
              />
            </div>
          </Grid>
        }
      </Grid>
    // </div>
  );
}
