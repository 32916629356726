import React, { Component } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";

class Partner extends Component {
    render() {
        return (
            <Container >
                <Row style={{ marginTop: 100 }}>
                    <Col sm={2}></Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize: 27, fontWeight: 'bold' }}>This page is under maintenance</Col>
                    <Col sm={2}></Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col></Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image src='Assets/gincode.png' style={{ width: 250, height: 250 }} />
                    </Col>
                    <Col></Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col sm={2}></Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 27, fontWeight: 'bold' }}>Sorry for the inconvenience</Col>
                    <Col sm={2}></Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col></Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button href="/" >Back to Home</Button></Col>
                    <Col></Col>
                </Row>
            </Container>
        );
    }
}

export default Partner;
