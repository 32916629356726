import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Container } from "react-bootstrap";
import "../Style/Navigation_Style.css";
import "../Style_v2/Footer_Styles.css";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col
            md={{span:3 }}          
          >
            <ul className="list-footer">
              <Link to="/Policy" className="headerFooterText">
                Kebijakan Privasi
              </Link>
              <Link to="/About" className="headerFooterText">
                Tentang Kami
              </Link>
            </ul>
          </Col>
          <Col
            md={{span:3 }}          
          >
            <ul className="list-footer">
              <li className="headerFooterText">Kontak</li>
              <div className="contact-container">
                <li className="contact-value">+62 812 8222 9798</li>
                <li className="contact-value">idepreneursclub@gmail.com</li>
              </div>
              <li className="headerFooterText">Media Sosial</li>
            </ul>
            <div className="media-sosial">
              <Link
                to={{ pathname: "https://facebook.com/idepreneursclub" }}
                target="_blank"
              >
                <Image className="medsosIcon" src="/Assets/fb_icn.svg" />
              </Link>
              <Link
                to={{ pathname: "https://www.instagram.com/idepreneursclub/" }}
                target="_blank"
              >
                <Image className="medsosIcon" src="/Assets/instagram_icn.svg" />
              </Link>
            </div>
          </Col>
          <Col
            md={{span:3 }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p className=" supportedBy headerFooterText">Supported By</p>
            <a href="https://gincodeteknologi.com/" className="otherLink">
              <Image
                src="/Assets/gincodewhite.png"
                className="supportedBy supportedImg"
              />
            </a>
            <p className="copyright">
              Copyright &copy;{new Date().getFullYear()} Idepreneurs.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
