import React from "react";
import { Image } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../Style_v2/Sponsor_Styles.css";

function SponsorHome(props) {
  const settings = {
    arrows: false,
    dots: false,
    autoPlaySpeed: 2000,
    autoplay: true,
    speed: 1000,
    slidesToShow: window.innerWidth > 992? 5 : window.innerWidth < 767? 2 : 3,
    slidesToScroll: 1,
    pauseOnHover:false,
    pauseOnFocus:false,
    infinite:false
  };
  return (
    <div className="sponsorContainer">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <div className="sponsor-home">
            <Slider {...settings}>
              {props.data.map((data, index) => {
                return (
                  <div className="logo-card" key={index}>
                    <Image src={data.logo} alt="" />
                  </div>
                );
              })}
            </Slider>
          </div>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>
    </div>
  );
}
export default SponsorHome;
