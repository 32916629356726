import React, { Component } from "react";
import { Row, Col, } from "react-bootstrap";
import { FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import ModalImage from "react-modal-image";
import "../Style/Executive_Style.css";
import "../Style/Voting_Style.css";
class Voting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: false,
    };
  }
  render() {
    return (
      this.state.activeData?
        <>
          <Row className="title">
            <Col>
              <h3 className="judul">Pemilihan Kandidat</h3>
            </Col>
          </Row>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="candidate"
              defaultValue={this.props.data[0]._id}
              name="candidate_id"
            >
              <Row>
                {this.generatePembina(this.props.data)}
              </Row>
            </RadioGroup>
          </FormControl>
          {/* <Row className="pembinaContent">
            <Col sm={6} md={6}>
              <h3 className="judul">Profile Kandidat</h3>
              <Row>
                  <Col sm={6} xs={6}>
                      <p className="inputProfile">Nama Kandidat</p>
                  </Col>
                  <Col sm={6} xs={6}>
                      <p className="pembinaDesc">{": " + this.state.activeData.member_id.name}</p>
                  </Col>
              </Row>
              <Row>
                  <Col sm={6} xs={6}>
                      <p className="inputProfile">Member Sejak</p>
                  </Col>
                  <Col sm={6} xs={6}>
                      <p className="pembinaDesc">{": " + this.state.activeData.member_since}</p>
                  </Col>
              </Row>
              <Row>
                  <Col sm={6} xs={6}>
                      <p className="inputProfile">Perusahaan</p>
                  </Col>
                  <Col sm={6} xs={6}>
                      <p className="pembinaDesc">{`: ${this.state.activeData.company_name?
                        this.state.activeData.company_name : "-"}`}</p>
                  </Col>
              </Row>
              <Row>
                  <Col sm={6} xs={6}>
                      <p className="inputProfile">Bussiness Fields</p>
                  </Col>
                  <Col sm={6} xs={6}>
                      <p className="pembinaDesc">{`: ${this.state.activeData.business_fields?
                        this.state.activeData.business_fields : "-"}`}</p>
                  </Col>
              </Row>
              <Row>
                  <Col sm={6} xs={6}>
                      <p className="inputProfile">Jabatan Terakhir</p>
                  </Col>
                  <Col sm={6} xs={6}>
                      <p className="pembinaDesc">{`: ${this.state.activeData.last_position?
                        this.state.activeData.last_position : "-"}`}</p>
                  </Col>
              </Row>
            </Col>
            <Col sm={true}>
              <h3 className="judul">Visi dan Misi</h3>
              <p className="pembinaDesc">{this.state.activeData.visi_misi}</p>
            </Col>
          </Row>
          {this.state.activeData.message?
            <Row>
              <Col>
                <h3 className="judul">Message</h3>
                <p className="pembinaDesc">{this.state.activeData.message}</p>
              </Col>
            </Row> 
            : <></> 
          } */}
        </>
        : <></>
    );
  }

  componentDidMount() {
    this.setActiveData(0);
  }
  setActiveData(idData) {
    const selectedData = typeof idData === 'number'?
      this.props.data[idData]
      : this.props.data.filter(res => res["_id"] === idData)[0];
    
    this.props.onChangeCandidate(selectedData);
    this.setState({
      activeData: selectedData,
      open: false,
    });
  }
  generatePembina = (data) => {
    var pembinaList = [];
    data.forEach((pembina) => {
      pembinaList.push(
        <Col
          sm={3}
          xs={6}
          key={pembina._id}
          className="voting"
        >
          <div className={"pembinaWrap"}>
            {/* <Image
              src={pembina.photo}
              width="100%"
              className={
                this.state.activeData._id === pembina._id
                ? "pembinaActive"
                : "pembina"
              }
            /> */}
            <ModalImage
              small={pembina.photo}
              large={pembina.photo}
              hideDownload={true}
              className={
                this.state.activeData._id === pembina._id
                ? "pembinaActive"
                : "pembina"
              }
            />
          </div>
          <FormControlLabel
            value={pembina._id}
            control={<Radio />}
            label={pembina.member_id.name}
            onClick={() => this.setActiveData(pembina._id)}
          />
        </Col>
      );
    });
    return pembinaList;
  };
}

export default Voting;
