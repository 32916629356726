//import
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

//Base URL
const BASE_URL = "https://api.idepreneursclub.org";
// const BASE_URL = "https://dev.idepreneursclub.org";

export const imageURI =
  "https://idepreneurs-uploaded-images.s3-ap-southeast-1.amazonaws.com";

//HOME
const GET_HOME_PAST_EVENT = "/v1/events?status=past&limit=5";
const GET_HOME_UPCOMING_EVENT = "/v1/events?status=upcoming&limit=4";
const GET_SPONSOR_LOGO = "​/v1/sponsors";
const GET_TOTAL_MEMBER = "/v1/member/count";

//sponsor
//get sponsor

//EVENT
// const GET_SPECIFIC_EVENT = "/v1/event";

//PATNERSHIP
const GET_ALL_PATNERSHIP = "/v1/partnerships";
//MERCHANT
const GET_ALL_MERCHANT = "/v1/merchants";
//CONTACT US
const POST_CONTACT_US = "/v1/contact-us/email";
//REGISTER MERCHANT
// const POST_MERCHANT_DATA = "/v1/merchant";

//Event Module
const GET_ALL_EVENT = "/v1/events";
// const GET_EVENT_DETAIL = "v1/events/";
// const POST_EVENT = "v1/event/";

const POST_IMAGE = "/v1/file";

//Member Module
const SIGNUP_MEMBER = "/v1/member";
const SIGNUP_MERCHANT = "/v1/merchant"

//General logic
export const hitAPI = async (URI, METHOD, HEADER, BODY) => {
  return await fetch(URI, {
    method: METHOD,
    header: HEADER,
    body: BODY
  }).then(response => response.json());
};
export const checkDataIsEmpty = data => {
  //console.log("------------------------");
  var flag = false;
  var dataTmp = [];
  for (let index = 0; index < data.length; index++) {
    if (!data[index] || data[index] === "") {
      //console.log("data ini kosong: " + index);
      flag = true;
      dataTmp.push(index);
    }
  }
  if (flag) {
    return [flag, dataTmp];
  } else {
    return [flag, null];
  }
};
export const checkSingleDataIsEmpty = data => {
  return data === null || data === "";
};
export const handleErrorRespon = respon => {
  //console.log(respon);
  if (respon.status) {
    return [respon.status, respon.data];
  } else {
    return [respon.status, respon.message];
  }
};
export const findElement = (arr, propName, propValue) => {
  if (arr === null) {
    return;
  }
  for (var i = arr.length - 1; i > 0; i--)
    if (arr[i][propName] === propValue) return arr[i].media_url;
};
export const galleryFilter = (arr, propName, propValue) => {
  var galleryImage = [];
  if (!arr) {
    return;
  }
  for (var i = arr.length - 1; i >= 0; i--) {
    if (arr[i][propName] === propValue) {
      galleryImage.push(imageURI + arr[i].media_url);
    }
    if (galleryImage.length >= 5) {
      return galleryImage;
    }
  }
  //console.log(galleryImage);
  return galleryImage;
};

// POST IMAGE FILE
export const postImageFile = async (uri, path) => {
  //console.log("Kesini ? : " + uri + " - " + path);
  let image = uri;
  console.log(image.size);
  if( uri.size > 1000000){
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(image, options); 
      console.log(image.size, compressedFile.size)  
      image = compressedFile;
    } catch (error) {
      console.log(error);
    }
  }
  var header = { "Content-Type": "application/json" };
  let body = new FormData();
  body.append("file", image);
  body.append("path", path);

  var obj = await hitAPI(BASE_URL + POST_IMAGE, "POST", header, body);
  if (obj.status === "success") {
    return [true, obj.data.path];
  } else {
    return [false, JSON.stringify(obj)];
  }
};

/**
 * Format date for API purpose
 * @param date = date which want to be formatted
 */
 export const formatDate = (date) => {
  if ( date != null ) {
      return date.substring(6,10) + "/" + date.substring(3,5) + "/" + date.substring(0,2);
  }
  else {
      return date;
  }
}

// REGISTER LOGIC
export const validateRegisterForm = formData => {
  var name = [
    "tanggal kelahiran",
    "email",
    "jenis kelamin",
    "menyetujui",
    "pekerjaan",
    "alasan bergabung",
    "membership",
    "nama",
    "foto KTP",
    "nomor telepon",
    "foto",
    "tempat lahir",
    "bukti pembayaran",
    "referensi",
    "Kota Domisili"
  ];
  var isPassValidate = true;
  var errTmp;
  var errorData = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ];
  for (let index = 0; index < formData.length; index++) {
    if (!formData[index] || formData[index] === "") {
      //console.log("data ini kosong: " + index);
      errTmp = {
        error: true,
        errMsg: "Mohon masukan " + name[index] + " anda"
      };
      errorData[index] = errTmp;
      isPassValidate = false;
    } else {
      switch (index) {
        case 1:
          if (
            !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/.test(
              formData[index]
            )
          ) {
            errTmp = { error: true, errMsg: "Format Email anda tidak benar" };
            errorData[index] = errTmp;
            isPassValidate = false;
          } else {
            errTmp = { error: false, errMsg: "" };
            errorData[index] = errTmp;
          }
          break;
        case 3:
          //console.log("menyetujui ??" + formData[index]);
          if(formData[index] === 0){
            errTmp = { error: true, errMsg: "Anda harus setuju dengan syarat dan ketentuan kami untuk mendaftar" };
            errorData[index] = errTmp;
            isPassValidate = false;
          } else {
            errTmp = { error: false, errMsg: "" };
            errorData[index] = errTmp;
          }
          break;
        case 7:
          if (!/^[a-zA-Z ]+$/.test(formData[index])) {
            errTmp = {
              error: true,
              errMsg: "Nama tidak boleh berisi angka atau simbol"
            };
            errorData[index] = errTmp;
            isPassValidate = false;
          } else {
            errTmp = { error: false, errMsg: "" };
            errorData[index] = errTmp;
          }
          break;
        case 9:
          if (!/\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/.test(formData[index])) {
            errTmp = {
              error: true,
              errMsg: "Format nomor telepon anda tidak benar"
            };
            errorData[index] = errTmp;
            isPassValidate = false;
          } else {
            errTmp = { error: false, errMsg: "" };
            errorData[index] = errTmp;
          }
          break;
        default:
          errTmp = { error: false, errMsg: "" };
          errorData[index] = errTmp;
          break;
      }
    }
  }
  return [isPassValidate, errorData];
};
export const postSignUp = async (
  city,
  company,
  date_of_birth,
  email,
  gender,
  is_agree,
  job,
  join_reason,
  membership,
  name,
  national_identification,
  phone_number,
  photo,
  place_of_birth,
  proof_of_payment,
  reference,
  reference_no
) => {
  var header = { "Content-Type": "application/json" };
  var body = JSON.stringify({
    city: city,
    company: company,
    date_of_birth: date_of_birth,
    email: email,
    gender: gender,
    is_agree: is_agree,
    job: job,
    join_reason: join_reason,
    membership: membership,
    name: name,
    national_identification: national_identification,
    phone_number: phone_number,
    photo: photo,
    place_of_birth: place_of_birth,
    proof_of_payment: proof_of_payment,
    reference: reference,
    reference_no: reference_no
  });

  var obj = await hitAPI(BASE_URL + SIGNUP_MEMBER, "POST", header, body);
  //console.log(JSON.stringify(obj));
  if (obj.status === "success") {
    return [true, obj.message];
  } else {
    return [false, obj.message];
  }
};

// HOME LOGIC
export const getMemberTotal = async () => {
  var header = { accept: "application/json" };

  var obj = await hitAPI(BASE_URL + GET_TOTAL_MEMBER, "GET", header);
  //console.log(obj.data.total_member);
  if (obj.status === "success") {
    return [true, obj.data.total_member];
  } else {
    return [false, JSON.stringify(obj)];
  }
};
export const getPastEvent = async () => {
  var header = { accept: "application/json" };
  var obj = await hitAPI(BASE_URL + GET_HOME_PAST_EVENT, "GET", header);
  //console.log("PAST EVENT: " + JSON.stringify(obj.data.data));
  if (obj.status === "success" && obj.data.data) {
    var tmp = [];
    obj.data.data.forEach((data, index) => {
      // var banner = findElement(data.media, "type", "banner");
      var thumbnail = findElement(data.media, "type", "thumbnail");
      tmp.push({
        id: index,
        idEvent: data.id,
        image: imageURI + thumbnail,
        date: data.date.split("T", 1),
        title: data.name,
        description: data.description
      });
    });
    return [true, tmp];
  } else {
    return [false, JSON.stringify(obj)];
  }
};
export const getUpcomingEvent = async () => {
  var header = { accept: "application/json" };
  var obj = await hitAPI(BASE_URL + GET_HOME_UPCOMING_EVENT, "GET", header);
  //console.log("UPCOMING EVENT: " + JSON.stringify(obj));
  if (obj.status === "success"  && obj.data.data) {
    var tmp = [];
    obj.data.data.forEach((data, index) => {
      var banner = findElement(data.media, "type", "banner");
      // var thumbnail = findElement(data.media, "type", "thumbnail");
      tmp.push({
        id: index,
        idEvent: data.id,
        image: imageURI + banner,
        date: data.date.split("T", 1),
        title: data.name,
        description: data.description
      });
    });
    return [true, tmp];
  } else {
    return [false, JSON.stringify(obj)];
  }
};
export const getSponsor = async () => {
  var header = { accept: "application/json" };
  var obj = await hitAPI(BASE_URL + GET_SPONSOR_LOGO, "GET", header);
  //console.log("SPONSOR: " + JSON.stringify(obj));
  if (obj.status === "success") {
    var tmp = [];
    obj.data.forEach((data, index) => {
      tmp.push({
        id: index,
        idSponsor: data.id,
        logo: imageURI + data.logo,
        level: data.level
      });
    });
    return [true, tmp];
  } else {
    return [false, JSON.stringify(obj)];
  }
};

// EVENT LOGIC
export const getAllEvent = async (
  status,
  city,
  category,
  start,
  end,
  limit,
  page
) => {
  var header = { "Content-Type": "application/json" };
  // var body = JSON.stringify({
  //   status: null,
  //   city: null,
  //   category: null,
  //   start: null,
  //   end: null,
  //   limit: null,
  //   page: null
  // });

  var obj = await hitAPI(
    BASE_URL +
    GET_ALL_EVENT +
    "?status=" +
    status +
    "&city=" +
    city +
    "&category=" +
    category +
    "&start=" +
    start +
    "&end=" +
    end +
    "&limit=" +
    limit +
    "&page=" +
    page,
    "GET",
    header
  );
  // console.log(JSON.stringify(obj));
  return obj;
};
// export const getSpecificEvent = async eventID => {
//   var header = { "Content-Type": "application/json" };
//   var obj = await hitAPI(
//     BASE_URL + GET_SPECIFIC_EVENT + "?eventID=" + parseInt(eventID),
//     "GET",
//     header
//   );
//   //console.log(obj.data);
//   if (obj.status === "success") {
//     var tmp = [];
//     var thumbnail = findElement(obj.data.media, "type", "thumbnail");
//     var gallery = galleryFilter(obj.data.media, "type", "image")
//     tmp = ({
//       idEvent: obj.data.id,
//       address: obj.data.address,
//       address_link: obj.data.address_link,
//       city: obj.data.city,
//       thumbnail: imageURI + thumbnail,
//       gallery: gallery,
//       date: obj.data.date.split("T", 1),
//       title: obj.data.name,
//       description: obj.data.description
//     });
//     return [true, tmp];
//   } else {
//     return [false, JSON.stringify(obj)];
//   }
// };


export const postAddMerchant = async (
  name,
  owner,
  phone_number,
  address,
  instagram,
  email,
  category,
  promo
  ) => {
    var header = { "Content-Type": "application/json" };
    var body = JSON.stringify({
      address: address,
      address_link: "",
      applier: owner,
      benefit: promo,
      city: "",
      company_field: category,
      duration: "",
      email: email,
      instagram: instagram,
      logo: "",
      name: name,
      phone_number:phone_number,
      status: "registered",
      tnc: "",
    });
  
    var obj = await hitAPI(BASE_URL + SIGNUP_MERCHANT, "POST", header, body);
    //console.log(JSON.stringify(obj));
    if (obj.status === "success") {
      return [true, obj.message];
    } else {
      return [false, obj.message];
    }
}

export const postContactUs = async (
  name,
  email,
  phone_number,
  message
  ) => {
    var header = { "Content-Type": "application/json" };
    var body = JSON.stringify({
      from_email: email,
      from_name: name,
      message:message,
      phone_number:phone_number
    });
  
    var obj = await hitAPI(BASE_URL + POST_CONTACT_US, "POST", header, body);
    //console.log(JSON.stringify(obj));
    if (obj.status === "success") {
      return [true, obj.message];
    } else {
      return [false, obj.message];
    }
}
export const sponsorType = (arr, propName, propValue) => {
  var galleryImage = [];
  if (!arr) {
    return;
  }
  for (var i = arr.length - 1; i > -1; i--) {
    if (arr[i][propName] === propValue) {
      galleryImage.push(arr[i].logo);
    }
  }
  return galleryImage;
};

//MERCHANT LOGIC

/**
 * Get all merchants
 */
 export const getMerchants = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/merchants",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

//PARTNER LOGIC

/**
 * Get all partners
 */
 export const getPartners = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/partnerships",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

/**
 * Translate error code to message
 * @param error: error code (ex: 404)
 */
 const handleError = error => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
      errorResponse = {
          status: 408,
          error: [{ msg: error.message }],
          message: "Request Time Out",
      };
  }
  else if (error.response) {
      errorResponse = error.response.data;
  }
  else {
      errorResponse = {
          status: 501,
          error: [{ msg: "Server Implementation Error" }],
      };
  }
  return errorResponse;
};

/**
 * Create new member
 * @param data: data required to create new member 
 */
 export const registerMember = async (data, birthPlace, domicile) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("phone_number", data.phone_number);
  form.append("email", data.email);
  form.append("address", data.address);
  form.append("shipping_address", data.shipping_address);
  if ( birthPlace === "Lainnya" ) {
    form.append("place_of_birth", data.place_of_birth);
  }
  else {
    form.append("place_of_birth", birthPlace);
  }
  const theDate = data.date_of_birth.substring(6,10) + "-" + data.date_of_birth.substring(3,5)
    + "-" + data.date_of_birth.substring(0,2);
  form.append("date_of_birth", theDate);
  if ( data.gender ) form.append("gender", data.gender);
  form.append("national_identification", data.national_identification[0]);
  if ( domicile === "Lainnya" ) {
    form.append("city", data.city);
  }
  else {
    form.append("city", domicile);
  }
  form.append("job", data.job);
  if ( data.join_reason.length <= 0 )
    form.append("join_reason", " ");
  else
    form.append("join_reason", data.join_reason);
  form.append("photo", data.photo[0]);
  form.append("membership", data.membership.value);
  form.append("reference_source", data.reference_source);
  form.append("reference", data.reference.value);
  form.append("clothes_size", data.clothes_size.value);
  form.append("registration_source", "admin");
  form.append("proof_of_payment", data.proof_of_payment[0]);
  form.append("isAgree", true);

  await Axios({
    method: "post",
    url: "/api/auth/register",
    baseURL: BASE_URL,
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Get all members
 */
export const getAllMembers = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/members/fetch/auto-complete",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get all candidates
 */
export const getCandidatesChairman = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/candidate-chairman",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Vote candidate
 * @param id: candidate's ID 
 * @param data: voter's name and membercard ID 
 */
export const voteCandidateChairman = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "member_card_id": data.value,
    "name": data.label,
  };

  await Axios({
    method: "post",
    url: "/api/candidate-chairman/vote_v2/" + id,
    baseURL: BASE_URL,
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Get all executive boards from API
 */
 export const getExecutiveBoards = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/boards",
      baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get all locations
 */
 export const getLocations = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/location",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get event categories
 */
 export const getEventCategories = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/event-category",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get all past events
 */
 export const getPastEvents = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/events/past",
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get specific event by ID
 * @param id: event's ID
 */
 export const getSpecificEvent = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "/api/events/" + id,
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get filtered events
 * @param data: filter condition
 * @param start: start index
 * @param total: total data which want to be fetched
 * @param isUpcoming: event data is upcoming event
 */
 export const getFilteredEvents = async (data, index, total, isUpcoming) => {
  var errorResponse = null;
  var tokenData;
  const eventType = isUpcoming ? "upcoming" : "past";
  let link = index + "/" + total + "?";
  if ( data.category ) {
    link += ("category=" + data.category.value);
    if ( data.location_id ) {
      link += ("&location_id=" + data.location_id);
    }
    if ( data.city ) {
      link += ("&city=" + data.city);
    }
  }
  else {
    if ( data.location_id && data.city ) {
      link += ("location_id=" + data.location_id + "&city=" + data.city);
    }
    else if ( data.location_id && !data.city ) {
      link += ("location_id=" + data.location_id);
    }
    else if ( !data.location_id && data.city ) {
      link += ("city=" + data.city);
    }
  }
  if ( data.first_date || data.last_date ) {
    if ( data.category || data.location_id || data.city ) {
      link += "&";
    }
  }
  if ( data.first_date && data.last_date ) {
    link += "start_date=" + (moment(data.first_date).format("YYYY-MM-DD") + "&end_date="
      + moment(data.last_date).format("YYYY-MM-DD"));
    }
  else if ( data.first_date && !data.last_date ) {
    link += "start_date=" + (moment(data.first_date).format("YYYY-MM-DD") + "&end_date="
      + moment().format("YYYY-MM-DD"));
  }
  else if ( !data.first_date && data.last_date ) {
    link += "start_date=" + (moment("01/01/2016").format("YYYY-MM-DD") + "&end_date="
      + moment(data.last_date).format("YYYY-MM-DD"));
  }
  
  await Axios({
      method: "get",
      url: "/api/events/filter/" + eventType + "/" + link,
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Get filtered upcoming events
 * @param data: filter condition
 */
 export const getFilteredUpcoming = async (data, index, total) => {
  var errorResponse = null;
  var tokenData;
  let link = index + "/" + total + "?";

  if ( data.category ) {
    link += ("category=" + data.category.value);
  }
  if ( data.first_date || data.last_date ) {
      link += "&";
  }
  if ( data.first_date && data.last_date ) {
    link += "start_date=" + (moment(data.first_date).format("YYYY-MM-DD") + "&end_date="
      + moment(data.last_date).format("YYYY-MM-DD"));
    }
  else if ( data.first_date && !data.last_date ) {
    link += "start_date=" + (moment(data.first_date).format("YYYY-MM-DD") + "&end_date="
      + moment().format("YYYY-MM-DD"));
  }
  else if ( !data.first_date && data.last_date ) {
    link += "start_date=" + (moment("01/01/2016").format("YYYY-MM-DD") + "&end_date="
      + moment(data.last_date).format("YYYY-MM-DD"));
  }
  
  await Axios({
      method: "get",
      url: "/api/events/filter/upcoming/" + link,
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
};

/**
 * Create new merchant
 * @param data: data required to create new merchant
 */
 export const postMerchant = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "name": data.name,
    "applier_name": data.applier_name,
    "phone_number": data.phone_number,
    "sosmed": data.sosmed,
    "address": data.address,
    "city": data.city,
    "email": data.email,
    "pic": data.pic,
    "pic_contact": data.pic_contact,
    "business_category": data.business_category,
    "member_benefit": data.member_benefit,
    "terms_and_conditions": data.terms_and_conditions,
  }

  await Axios({
    method: "post",
    url: "/api/merchants",
    baseURL: BASE_URL,
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};