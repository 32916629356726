import React from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import Fade from 'react-reveal/Fade';
import { Container, Row, Col, Image} from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import "../Style/AboutUs_Style.css";

export function Policy() {
  window.scrollTo(0,0);
  const kebijakan = ["Kami tunduk terhadap kebijakan perlindungan data pribadi pengguna sebagaimana yang diatur dalam Peraturan Menteri Komunikasi dan Informatika nomor 20 Tahun 2016 Tentang Perlindungan Data Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan data dan informasi penting para pengguna nya.", "Kami melindungi segala informasi yang diberikan pengguna pada saat pendaftaran, mengakses dan menggunakan seluruh layanan aplikasi.", "Kami Berhak menggunakan data dan informasi para pengguna demi meningkatkan mutu dan pelayanan organisasi Ide Indonesia Entrepreneurs.", "Data pengguna yang tersimpan di dalam aplikasi Idepreneurs hanya digunakan untuk keperluan fungsional aplikasi. Kami tidak menyimpan data pengguna untuk keperluan marketing ataupun analisis.", "Kami Tidak Bertanggung jawab atas pertukaran data yang dilakukan sendiri di antara pengguna.", "Kami hanya dapat memberitahukan data dan informasi yang dimiliki oleh para pengguna bila diwajibkan dan/atau diminta oleh Institusi yang berwenang berdasarkan ketentuan hukum yang berlaku, perintah resmi dari pengadilan dan/atau perintah resmi dari instansi atau aparat yang bersangkutan."]
    return (
      <Container fluid className='bg policy'>
        <NavbarCenter />
        <Row className="aboutImage">
          <Col sm={{ span: 10, offset: 1 }}>
            <Row>
              <Col className="pastEvent w-100">
                  <Row>                              
                    <Col sm={12}> 
                    <h3 className="judul">Kebijakan Privasi</h3>
                    <p className="tanggal">Adanya kebijakan privasi ini adalah untuk menghargai dan melindungi setiap informasi pribadi pengguna aplikasi. Dengan mengakses dan menggunakan layanan aplikasi Idepreneurs, pengguna dianggap telah membaca, memahami, dan memberikan persetujuan nya terhadap pengumpulan dan penggunaan data pribadi pengguna sebagaimana diuraikan berikut dibawah ini:</p>
                    <Fade right cascade duration={1000}>                
                      {kebijakan.map((v=>
                          {
                            return(
                              <div className="visiDanMisi">
                                <div className="listImage">
                                  <Image src='/Assets/list.png' alt='tes' className='list' />
                                </div>
                                <div className="listText">
                                  <p className="tanggal">{v}
                                  </p>
                                </div>
                              </div>  
                            )
                          }
                      ))}                
                    </Fade>
                    </Col>
                  </Row>
                </Col>
            </Row >
          </Col>
        </Row>
        <Footer />
      </Container >
    );
  }
export default Policy;
