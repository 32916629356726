export default [
    "Aceh", 
    "Agam", 
    "Alor", 
    "Ambon", 
    "Asahan", 
    "Asmat", 
    "Batam", 
    "Badung", 
    "Balangan", 
    "Balikpapan", 
    "Banda Aceh", 
    "Bandar Lampung", 
    "Bandung", 
    "Banggai", 
    "Banggai Kepulauan", 
    "Bangka", 
    "Bangkalan", 
    "Bangli", 
    "Banjar", 
    "Banjar Baru", 
    "Banjarmasin", 
    "Banjarnegara", 
    "Bantaeng", 
    "Bantul", 
    "Banyu Asin", 
    "Banyumas", 
    "Banyuwangi", 
    "Barito", 
    "Barru", 
    "Baru", 
    "Batang", 
    "Batang Hari", 
    "Batu", 
    "Batu Bara", 
    "Baubau", 
    "Bekasi", 
    "Belitung", 
    "Belu", 
    "Bener Meriah", 
    "Bengkalis", 
    "Bengkayang", 
    "Bengkulu", 
    "Berau", 
    "Biak Numfor", 
    "Bima", 
    "Binjai", 
    "Bintan", 
    "Bireuen", 
    "Bitung", 
    "Blitar", 
    "Blora", 
    "Boalemo", 
    "Bogor", 
    "Bojonegoro", 
    "Bolaang Mongondow",
    "Bombana", 
    "Bondowoso", 
    "Bone", 
    "Bone Bolango", 
    "Bontang", 
    "Boven Digoel", 
    "Boyolali", 
    "Brebes", 
    "Bukittinggi", 
    "Buleleng", 
    "Bulukumba", 
    "Bulungan", 
    "Bungo", 
    "Buol", 
    "Buru", 
    "Buton", 
    "Ciamis", 
    "Cianjur", 
    "Cilacap", 
    "Cilegon", 
    "Cimahi", 
    "Cirebon", 
    "Dumai", 
    "Dairi", 
    "Deiyai", 
    "Deli Serdang", 
    "Demak", 
    "Denpasar", 
    "Depok", 
    "Dharmasraya", 
    "Dogiyai", 
    "Dompu", 
    "Donggala", 
    "Empat Lawang", 
    "Ende", 
    "Enrekang", 
    "Fakfak", 
    "Flores", 
    "Garut", 
    "Gayo Lues", 
    "Gianyar", 
    "Gorontalo", 
    "Gowa", 
    "Gresik", 
    "Grobogan", 
    "Gunung Kidul", 
    "Gunung Mas", 
    "Gunungsitoli", 
    "Halmahera", 
    "Hulu Sungai", 
    "Humbang Hasundutan", 
    "Indragiri Hilir", 
    "Indragiri Hulu", 
    "Indramayu", 
    "Intan Jaya", 
    "Jakarta", 
    "Jambi", 
    "Jayapura", 
    "Jayawijaya", 
    "Jember", 
    "Jembrana", 
    "Jeneponto", 
    "Jepara", 
    "Jombang", 
    "Kaimana", 
    "Kampar", 
    "Kapuas", 
    "Kapuas Hulu", 
    "Karang Asem", 
    "Karanganyar", 
    "Karawang", 
    "Karimun", 
    "Karo", 
    "Katingan", 
    "Kaur", 
    "Kayong", 
    "Kebumen", 
    "Kediri", 
    "Keerom", 
    "Kendal", 
    "Kendari", 
    "Kepahiang", 
    "Kepulauan Anambas", 
    "Kepulauan Aru", 
    "Kepulauan Mentawai", 
    "Kepulauan Meranti", 
    "Kepulauan Sangihe", 
    "Kepulauan Selayar", 
    "Kepulauan Seribu", 
    "Kepulauan Sula", 
    "Kepulauan Talaud", 
    "Kepulauan Yapen", 
    "Kerinci", 
    "Ketapang", 
    "Klaten", 
    "Klungkung", 
    "Kolaka",  
    "Konawe",  
    "Kotamobagu", 
    "Kotawaringin", 
    "Kuantan Singingi", 
    "Kubu Raya", 
    "Kudus", 
    "Kulon Progo", 
    "Kuningan", 
    "Kupang", 
    "Kutai",  
    "Labuhan Batu", 
    "Lahat", 
    "Lainnya", 
    "Lamandau", 
    "Lamongan", 
    "Lampung", 
    "Landak", 
    "Langkat", 
    "Langsa", 
    "Lanny Jaya", 
    "Lebak", 
    "Lebong", 
    "Lembata", 
    "Lhokseumawe", 
    "Lima Puluh Kota", 
    "Lingga", 
    "Lombok", 
    "Lubuklinggau", 
    "Lumajang", 
    "Luwu", 
    "Madiun", 
    "Magelang", 
    "Magetan", 
    "Majalengka", 
    "Majene", 
    "Makassar", 
    "Malang", 
    "Malinau", 
    "Maluku", 
    "Mamasa", 
    "Mamberamo",  
    "Mamuju", 
    "Manado", 
    "Mandailing Natal", 
    "Manggarai", 
    "Manokwari", 
    "Mappi", 
    "Maros", 
    "Mataram", 
    "Maybrat", 
    "Medan", 
    "Melawi", 
    "Merangin", 
    "Merauke", 
    "Mesuji", 
    "Metro", 
    "Mimika", 
    "Minahasa", 
    "Mojokerto", 
    "Morowali", 
    "Muara Enim", 
    "Muaro Jambi", 
    "Mukomuko", 
    "Muna", 
    "Murung Raya", 
    "Musi Banyuasin", 
    "Musi Rawas", 
    "Nabire", 
    "Nagan Raya", 
    "Nagekeo", 
    "Natuna", 
    "Nduga", 
    "Ngada", 
    "Nganjuk", 
    "Ngawi", 
    "Nias", 
    "Nunukan", 
    "Ogan Ilir", 
    "Pacitan", 
    "Padang", 
    "Padang Lawas", 
    "Padang Panjang", 
    "Padang Pariaman", 
    "Padangsidimpuan", 
    "Pagar Alam", 
    "Pakpak Bharat", 
    "Palangka Raya", 
    "Palembang", 
    "Palopo", 
    "Palu", 
    "Pamekasan", 
    "Pandeglang", 
    "Pangandaran", 
    "Pangkajene Dan Kepulauan", 
    "Pangkalan Bun",
    "Pangkal Pinang", 
    "Paniai", 
    "Parepare", 
    "Pariaman", 
    "Parigi Moutong", 
    "Pasaman", 
    "Paser", 
    "Pasuruan", 
    "Pati", 
    "Payakumbuh", 
    "Pegunungan Bintang", 
    "Pekalongan", 
    "Pekanbaru", 
    "Pelalawan", 
    "Pemalang", 
    "Pematang Siantar", 
    "Penajam Paser", 
    "Pesawaran", 
    "Pesisir",  
    "Pidie", 
    "Pidie Jaya", 
    "Pinrang", 
    "Pohuwato", 
    "Polewali Mandar", 
    "Ponorogo", 
    "Pontianak", 
    "Poso", 
    "Prabumulih", 
    "Pringsewu", 
    "Probolinggo", 
    "Pulang Pisau", 
    "Pulau Morotai", 
    "Puncak", 
    "Puncak Jaya", 
    "Purbalingga", 
    "Purwakarta", 
    "Purworejo", 
    "Raja Ampat", 
    "Rejang Lebong", 
    "Rembang", 
    "Rokan Hilir", 
    "Rokan Hulu", 
    "Rote Ndao", 
    "Siak", 
    "Sabang", 
    "Sabu Raijua", 
    "Salatiga", 
    "Samarinda", 
    "Sambas", 
    "Samosir", 
    "Sampang", 
    "Sanggau", 
    "Sarmi", 
    "Sarolangun", 
    "Sawah Lunto", 
    "Sekadau", 
    "Seluma", 
    "Semarang", 
    "Seram",  
    "Serang", 
    "Serdang Bedagai", 
    "Seruyan", 
    "Siau Tagulandang Biaro", 
    "Sibolga", 
    "Sidenreng Rappang", 
    "Sidoarjo", 
    "Sigi", 
    "Sijunjung", 
    "Sikka", 
    "Simalungun", 
    "Simeulue", 
    "Singkawang", 
    "Sinjai", 
    "Sintang", 
    "Situbondo", 
    "Sleman", 
    "Solok", 
    "Soppeng", 
    "Sorong",  
    "Sragen", 
    "Subang", 
    "Subulussalam", 
    "Sukabumi", 
    "Sukamara", 
    "Sukoharjo", 
    "Sumba",  
    "Sumbawa", 
    "Sumedang", 
    "Sumenep", 
    "Sungai Penuh", 
    "Supiori", 
    "Surabaya", 
    "Surakarta", 
    "Tabalong", 
    "Tabanan", 
    "Takalar", 
    "Tambrauw", 
    "Tana Tidung", 
    "Tana Toraja", 
    "Tanah Bumbu", 
    "Tanah Datar", 
    "Tanah Laut", 
    "Tangerang", 
    "Tanggamus", 
    "Tanjung Balai", 
    "Tanjung Jabung",  
    "Tanjung Pinang", 
    "Tapanuli",  
    "Tapin", 
    "Tarakan", 
    "Tasikmalaya", 
    "Tebing Tinggi", 
    "Tebo", 
    "Tegal", 
    "Teluk Bintuni", 
    "Teluk Wondama", 
    "Temanggung", 
    "Ternate", 
    "Tidore Kepulauan", 
    "Timor Tengah", 
    "Toba Samosir", 
    "Tojo Una-una", 
    "Toli-toli", 
    "Tolikara", 
    "Tomohon", 
    "Toraja", 
    "Trenggalek", 
    "Tual", 
    "Tuban",  
    "Tulang Bawang", 
    "Tulungagung", 
    "Wajo", 
    "Wakatobi", 
    "Waropen", 
    "Way Kanan", 
    "Wonogiri", 
    "Wonosobo", 
    "Yahukimo", 
    "Yalimo", 
    "Yogyakarta"
]