import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import { postContactUs } from "../Logic/Share_Logic";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone_number: "",
      message: "",
      showModal: false,
      error: true,
      errorMessage: null,
      errorWarning: null,
      isLoading: false,
    };
  }

  handleChange = (e) => {
    console.log(e.target.name);
    switch (e.target.name) {
      case "email":
        var name = e.target.value;
        name = name.replace(/\s+/g, "");
        break;
      case "name":
        var re = /(\b[a-z](?!\s))/g;
        name = e.target.value.replace(re, function (x) {
          return x.toUpperCase();
        });
        name = name.replace(/\s\s+/g, " ");
        break;
      default:
        name = e.target.value;
        break;
    }
    console.log(name);
    this.setState({ [e.target.name]: name });
    console.log(e.target.name + "  :  " + name);
  };

  checkDataIsEmpty = (data) => {
    console.log("------------------------");
    var flag = false;
    var dataTmp = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index] == null || data[index] === "") {
        console.log("data ini kosong: " + index);
        flag = true;
        dataTmp.push(index);
      }
    }
    if (flag) {
      return [flag, dataTmp];
    } else {
      return [flag, null];
    }
  };

  setLoading(state) {
    this.setState({
      isLoading: state,
    });
    console.log("Loading ? :" + state);
  }

  handleClose = (e) => {
    this.setState({ showModal: false });
    if (!this.state.error) {
      this.props.history.push("");
    }
  };

  checkdataifEmpty() {
    var error = this.checkDataIsEmpty([
      this.state.name,
      this.state.email,
      this.state.phone_number,
      this.state.message,
    ]);
    if (error[0]) {
      console.log("Error : " + error[1]);
      var errorTmp = [];
      var errorWar = this.state.errorWarning;
      error[1].map((x) => {
        switch (x) {
          case 0:
            errorTmp.push(<p>&#8226; Mohon Masukan Nama anda</p>);
            errorWar[0] = true;
            break;
          case 1:
            errorTmp.push(<p>&#8226; Mohon Masukan Email anda</p>);
            errorWar[1] = true;
            break;
          case 2:
            errorTmp.push(<p>&#8226; Mohon Masukkan Nomor Kontak anda</p>);
            errorWar[2] = true;
            break;
          case 3:
            errorTmp.push(<p>&#8226; Mohon Masukkan Pesan anda</p>);
            errorWar[3] = true;
            break;
          default:
            break;
        }
        return "";
      });
      console.log("gagal !");
      this.setState({
        errorMessage: errorTmp,
        error: true,
        showModal: true,
        errorWarning: errorWar,
      });
      return true;
    } else {
      console.log("sukses !");
      this.setState({
        errorMessage: null,
        error: false,
        errorWarning: [false, false, false, false],
      });
      return false;
    }
  }

  validateContactForm = (formData) => {
    var name = ["nama", "email", "nomor kontak", "pesan"];
    var isPassValidate = true;
    var errTmp;
    var errorData = [null, null, null, null];
    for (let index = 0; index < formData.length; index++) {
      if (formData[index] === null || formData[index] === "") {
        console.log("data ini kosong: " + index);
        errTmp = {
          error: true,
          errMsg: "Mohon masukan " + name[index] + " anda",
        };
        errorData[index] = errTmp;
        isPassValidate = false;
      } else {
        switch (index) {
          case 0:
            if (!/^[a-zA-Z]+$/.test(formData[index])) {
              console.log(formData[index]);
              errTmp = {
                error: true,
                errMsg: "Input tidak boleh berisi angka atau simbol",
              };
              errorData[index] = errTmp;
              isPassValidate = false;
            } else {
              errTmp = { error: false, errMsg: "" };
              errorData[index] = errTmp;
            }
            break;
          case 1:
            if (
              !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/.test(
                formData[index]
              )
            ) {
              errTmp = { error: true, errMsg: "Format Email anda tidak benar" };
              errorData[index] = errTmp;
              isPassValidate = false;
            } else {
              errTmp = { error: false, errMsg: "" };
              errorData[index] = errTmp;
            }
            break;
          case 2:
            if (!/\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/.test(formData[index])) {
              errTmp = {
                error: true,
                errMsg: "Format nomor telepon anda tidak benar",
              };
              errorData[index] = errTmp;
              isPassValidate = false;
            } else {
              errTmp = { error: false, errMsg: "" };
              errorData[index] = errTmp;
            }
            break;
          default:
            errTmp = { error: false, errMsg: "" };
            errorData[index] = errTmp;
            break;
        }
      }
    }
    return [isPassValidate, errorData];
  };

  async submitContactUs() {
    var validateData = this.validateContactForm([
      this.state.name,
      this.state.email,
      this.state.phone_number,
      this.state.message,
    ]);
    if (!validateData[0]) {
      console.log(validateData[1]);
      this.setState({ errorWarning: validateData[1] });
      return;
    }
    this.setLoading(true);
    if (this.checkdataifEmpty()) {
      this.setLoading(false);
      return;
    } else {
      var respon = await postContactUs(
        this.state.name,
        this.state.email,
        this.state.phone_number,
        this.state.message
      );
      if (respon[0]) {
        this.setLoading(false);
        this.setState({
          showModal: true,
        });
      } else {
        this.setLoading(false);
        this.setState({
          errorMessage:
            "Ada masalah saat mengirim data, mohon tunggu sebentar, atau hubungi admin untuk informasi lebih lanjut",
          error: true,
          showModal: true,
        });
      }
    }
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Mohon tunggu sebentar"
      >
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.error ? "Error !" : "Sukses !"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error
              ? this.state.errorMessage
              : "Pesan anda telah kami rekam. Terima kasih & mohon tunggu respon dari admin kami untuk membalas pesan anda"}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Container fluid className="bg">
          <NavbarCenter />
          <Row className="bgImage">
            <Col sm={{ span: 10, offset: 1 }}>
              <Form className="formContainer">
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nama</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      <p className="errorText">
                        {this.state.errorWarning
                          ? this.state.errorWarning[0].errMsg
                          : ""}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <p className="errorText">
                        {this.state.errorWarning
                          ? this.state.errorWarning[1].errMsg
                          : ""}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nomor Kontak</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone_number"
                        value={this.state.phone_number}
                        onChange={this.handleChange}
                      />
                      <p className="errorText">
                        {this.state.errorWarning
                          ? this.state.errorWarning[2].errMsg
                          : ""}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Pesan</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="10"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                      <p className="errorText">
                        {this.state.errorWarning
                          ? this.state.errorWarning[3].errMsg
                          : ""}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex" }}>
                    <Button
                      className="contactButton"
                      onClick={() => this.submitContactUs()}
                    >
                      Kirim
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col>
                  <h3 className="judul">Alamat Kantor</h3>
                </Col>
              </Row>
              <Row>
                <Col sm={4} style={{ marginBottom: 30 }}>
                  <p className="addressHeader">Kantor Sekretariat</p>
                  <p className="address">Triraksa Village Blok A1 No. 1A</p>
                  <p className="address">Jln. Raya Tigaraksa - Cisoka</p>
                  <p className="address">Kel. Tigaraksa</p>
                  <p className="address">Kec. Tanggerang</p>
                  <p className="address">Kab. Tanggerang</p>
                  <p className="address">Banten</p>
                  <p className="address">15720</p>
                </Col>
                <Col sm={4} style={{ marginBottom: 30 }}>
                  <p className="addressHeader">IDE SPACE</p>
                  <p className="address">Golf Island L 16</p>
                  <p className="address">Pantai Indah Kapuk</p>
                  <p className="address">Kel. Kamal Muara</p>
                  <p className="address">Kec. Penjaringan</p>
                  <p className="address">Jakarta Utara</p>
                  <p className="address">DKI Jakarta</p>
                  <p className="address">14470</p>
                </Col>
                <Col sm={4} style={{ marginBottom: 30 }}>
                  <p className="addressHeader">
                    Kantor Administrasi
                  </p>
                  <p className="address">Gedung Tomang Tol Swalayan Lt.4</p>
                  <p className="address">Jln. Arjuna Utara No.1</p>
                  <p className="address">Kel. Tanjung Duren Selatan</p>
                  <p className="address">Kec. Grogol pertamburan</p>
                  <p className="address">Jakarta Barat</p>
                  <p className="address">DKI Jakarta</p>
                  <p className="address">11470</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer />
        </Container>
      </LoadingOverlay>
    );
  }
}

export default Contact;
