import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Container, Row, Col, } from "react-bootstrap";
import Footer from "../Component/Footer_Com";
import {
  getMemberTotal,
  getPastEvent,
  getUpcomingEvent,
  getSponsor,
  getFilteredUpcoming,
} from "../Logic/Share_Logic";
import "../Style/Default_Style.css";
import "../Style/Register_Style.css";
import "../Style/Home_Style.css";
import "../Style/Sponsor_Style.css";
// import { Link } from "react-router-dom";
// import Image from "/Assets/fb_icn.svg";
// import SponsorHome from "../Component/SponsorHome_Com"; // {v1}
import SponsorHome from "../Component_v2/Sponsor_Com";
// import HomeSlider from "../Component/HomeSlider_Com"; // {v1}
import HomeSlider from "../Component_v2/HomeSlider_Com"; // {v2}
// import { EventHome, EventHomeM } from "../Component/EventHome_Com"; // {v1}
import EventHome from "../Component_v2/EventHome_Com"; // {v2}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      eventData: [],
      open: true,
      totalMember: 0,
      pastEvent: [],
      upcomingEvent: [],
      sponsor: [],
      dataHome: null,
      windowsWidth: 0,
      didViewCountUp: false,
      counter: 1,
      currPage: 1,
      totalPage: 1,
      numberRegex: /^[0-9]*$/,
      state: {
        chapter: null,
        category: null,
        first_date: null,
        last_date: null,
      }
    };
    this.onChangePagination = this.onChangePagination.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.onBlurPagination = this.onBlurPagination.bind(this);
  }
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowsWidth: window.innerWidth });
  };

  // Make the website stay still for __ ms
  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  // Update value on pagination textfield
  onChangePagination(value) {
    if ( this.state.numberRegex.test(value) ) {
      if ( value === "0" ) {
        this.setState({ counter: 1 });
      }
      else if ( parseInt(value) > parseInt(this.state.totalPage) ) {
        this.setState({ counter: this.state.totalPage });
      }
      else {
        this.setState({ counter: value });
      }
    }
  };
  // Apply change page on-blur pagination textfield
  async onBlurPagination() {
    if ( this.state.counter ) {
      this.setState({ isLoading: true });
      let resp = await getFilteredUpcoming(this.state.state, this.state.counter, 15);
      if ( resp[0] && resp[0].status === 200 ) {
        this.setState({
          currPage: this.state.counter,
          upcomingEvent: resp[0].data.events,
        });
      }
      else {
        console.log(resp);
      }
      await this.sleep(1000);
      this.setState({ isLoading: false });
    }
  };
  // Change page when user click next/prev arrow button
  async handlePagination(type) {
    this.setState({ isLoading: true });
    let resp = "";
    // let tempPage = 0;
    if ( type === 'prev' ) {
      resp = await getFilteredUpcoming(this.state.state, parseInt(this.state.counter) - 1, 15);
      this.setState({
        counter: parseInt(this.state.counter) - 1,
        currPage: parseInt(this.state.counter) - 1,
      });
      // tempPage = parseInt(this.state.counter) - 1;
    }
    else if ( type === 'next' ) {
      resp = await getFilteredUpcoming(this.state.state, parseInt(this.state.counter) + 1, 15);
      this.setState({
        counter: parseInt(this.state.counter) + 1,
        currPage: parseInt(this.state.counter) + 1
      });
      // tempPage = parseInt(this.state.counter) + 1;
    }
    
    if ( resp[0] && resp[0].status === 200 ) {
      this.setState({ upcomingEvent: resp[0].data.events });
    }
    else {
      console.log(resp);
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Container fluid className="bg">
        <NavbarCenter windowsWidth={this.state.windowsWidth} />
        {/*slider*/}
        <HomeSlider data={this.state.eventData} />
        {this.state.upcomingEvent.length !== 0 ? (
          <Row className="eventHomeContainer">
            <Col md={{ span: 10, offset: 1 }}>
              <Row className="eventHomeContent">
                <Col className="contentSpacing w-100">
                  <Row>
                    <Col>
                      <h3 className="judul">Kegiatan</h3>
                    </Col>
                  </Row>
                  <EventHome
                    isUpcoming={true}
                    eventData={this.state.upcomingEvent}
                    counter={this.state.counter}
                    currPage={this.state.currPage}
                    totalPage={this.state.totalPage}
                    onChangePagination={(e) => this.onChangePagination(e.target.value)}
                    onBlurPagination={() => this.onBlurPagination()}
                    handlePagination={(type) => this.handlePagination(type)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          ""
          // <Row className="eventHomeContainer">
          //   <Col md={{ span: 10, offset: 1 }}>
          //     <Row className="eventHomeContent">
          //       <Col className="contentSpacing w-100">
          //         <Row>
          //           <Col>
          //             <h3 className="judul">Kegiatan</h3>
          //           </Col>
          //         </Row>
          //         <EventHome eventData={this.state.pastEvent} />
          //       </Col>
          //     </Row>
          //   </Col>
          // </Row>
        )}

        {/*Member*/}
        <Row className="homeParalax">
          <Col>
            <Row>
              <Col sm={{ span: 4, offset: 1 }} className="totalAnggota">
                <h1 className="judul">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{ top: 10 }}
                    delayedCall
                  >
                    <CountUp
                      start={0}
                      // end={this.state.didViewCountUp ? parseInt(this.state.totalMember) : 0}
                      end={this.state.totalMember}
                      duration={5}
                    />
                  </VisibilitySensor>
                </h1>
                <p className="basic">Anggota</p>
              </Col>
              <Col sm={6}>
                ``
                <p className="basic">
                  Idepreneurs Club adalah organisasi yang didirikan di tahun
                  2016 oleh sekelompok pemuda yang mempunyai visi dan misi yang
                  seiring dengan perkembangan Pemerintah Indonesia yang terus
                  berusaha menggenjot pertumbuhan wirausaha agar dapat menjadi
                  seperti negara maju yang pertumbuhan ekonominya dimotori oleh
                  wiraswasta. Berbagai cara dilakukan untuk membentuk pikiran
                  masyarakat agar menjadi pengusaha dan tidak hanya menjadi
                  pencari kerja.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Sponsor */}
        {this.state.sponsor.length === 0 ? null : (
          <SponsorHome data={this.state.sponsor} />
        )}
        <Footer />
      </Container>
    );
  }

  componentDidMount() {
    // this.fetchDataHome();
    this.updateWindowDimensions();
    this.getDataHome();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  async fetchDataHome() {
    var totalMember = await getMemberTotal();
    totalMember = totalMember[0] ? totalMember[1] : null;
    var pastEvent = await getPastEvent();
    pastEvent = pastEvent[0] ? pastEvent[1] : null;
    var upcomingEvent = await getUpcomingEvent();
    upcomingEvent = upcomingEvent[0] ? upcomingEvent[1] : null;
    var sponsor = await getSponsor();
    sponsor = sponsor[0] ? sponsor[1] : null;
    this.setState({
      totalMember: totalMember,
      eventData: pastEvent,
      upcomingEvent: upcomingEvent,
      totalPage: Math.ceil(upcomingEvent.length / 15),
      sponsor: sponsor,
    });
    this.setActiveData(0);
  }

  async getDataHome() {
    const url = "https://api.idepreneursclub.org/fetch/home";
    // const url = "https://dev.idepreneursclub.org/fetch/home";
    let response = "";
    try {
      response = await fetch(url);
      const data = await response.json();
      if (data.status === 200 && data) {
        const dataHome = data.data;
        const newEvent = dataHome.upcomingEvent.filter(
          (res) => res["isDeleted"] === false
        );
        const pastEvent = dataHome.pastEvent.filter(
          (res) => res["isDeleted"] === false
        );
        this.setState({
          totalMember: dataHome.totalMembers,
          eventData: dataHome.banners,
          sponsor: dataHome.sponsorsLogo,
          pastEvent: dataHome.pastEvent,
          totalPage: Math.ceil(dataHome.upcomingEvent.length / 15),
        });
        if (newEvent.length > 3) {
          this.handleDataEvent(dataHome);
        } else if (newEvent.length <= 3 && newEvent.length !== 0) {
          this.setState({ upcomingEvent: newEvent });
        } else if (pastEvent.length > 3) {
          this.handlePastDataEvent(dataHome);
        } else {
          this.setState({ pastEvent: pastEvent });
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  handleDataEvent(data) {
    let newEvent = data.upcomingEvent.filter(
      (res) => res["isDeleted"] === false
    );
    let tempEvent = [];
    for (let i = newEvent.length - 3; i < newEvent.length; i++) {
      tempEvent.push({
        banner: newEvent[i].banner,
        date: newEvent[i].date,
        name: newEvent[i].name,
        description: newEvent[i].description,
        address: newEvent[i].address,
      });
    }
    this.setState({ upcomingEvent: tempEvent });
  }

  handlePastDataEvent(data) {
    const pastEvent = data.pastEvent.filter(
      (res) => res["isDeleted"] === false
    );
    let tempPastEvent = [];
    for (let i = 0; i < 3; i++) {
      tempPastEvent.push({
        banner: pastEvent[i].banner,
        date: pastEvent[i].date,
        name: pastEvent[i].name,
        description: pastEvent[i].description,
        address: pastEvent[i].address,
      });
    }
    this.setState({ pastEvent: tempPastEvent });
  }

  setActiveData(idData) {
    this.setState({
      activeData: this.state.eventData ? this.state.eventData[idData] : [],
      open: false,
    });
  }

  fade() {
    this.setState({
      open: true,
    });
  }
}

export default Home;
