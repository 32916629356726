import React from "react";
// import Jumbotron from "react-bootstrap/Jumbotron";
// import Carousel from "react-bootstrap/Carousel";
// import Image from "react-bootstrap/Image";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../Style_v2/Slider_Style.css";

function HomeSlider_v2(props) {
  return (
    <div id="home" className="site-page-carousel">
      <GenerateCarousel data={props.data} />
    </div>
  );
  // else {
  //   return (
  //     <Jumbotron>
  //       <Carousel
  //         style={{
  //           maxHeight: "540px",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Carousel.Item>
  //           <Image
  //             className="d-block w-100"
  //             src="/Assets/news-01.png"
  //             fluid
  //             style={{ maxHeight: "540px" }}
  //           />
  //           <Carousel.Caption>
  //             <Button className="sliderButton" href="/Register">
  //               Daftar
  //             </Button>
  //           </Carousel.Caption>
  //         </Carousel.Item>
  //         {generatejumbotron(props)}
  //       </Carousel>
  //     </Jumbotron>
  //   );
  // }
}
// const data = [
//   {
//     image: "/Assets/left.jpg",
//     title: "Idepreneurs",
//     ket: "Idepreneurs Club adalah organisasi yang didirikan di tahun 2016",
//     button: "Lihat",
//   },
//   {
//     image: "/Assets/right.jpg",
//     title: "Idepreneurs",
//     ket: "Idepreneurs Club adalah organisasi yang didirikan di tahun 2016",
//     button: "Lihat",
//   },
//   {
//     image: "/Assets/left.jpg",
//     title: "Idepreneurs",
//     ket: "Idepreneurs Club adalah organisasi yang didirikan di tahun 2016",
//     button: "Lihat",
//   },
// ];
function GenerateCarousel(props) {
  const settings = {
    dots: true,
    customPaging: (i) => <div className="slick-custom-dots"></div>,
    draggable: true,
    infinite: true,
    // autoplay: true,
    speed: 1000,
    fade: true,
    lazyload: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    pauseOnFocus: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  var cards = [];
  props.data.forEach((data, index) => {
    // Left Banner
    if (data.position === "left") {
      cards.push(
        <div key={index} className="site-page-carousel-inner">
          <Link to={{ pathname: data.link}} target="_blank">
            <img className="img-banner" src={data.banner} alt="gambar" />
          </Link>
          <div className="container-text left">
            <h1>{data.title}</h1>
            <p>{data.sub_title}</p>
          </div>
        </div>
      );
    }
    // Right Banner
    else {
      cards.push(
        <div key={index} className="site-page-carousel-inner">
          <Link to={{ pathname: data.link}} target="_blank">
            <img className="img-banner" src={data.banner} alt="gambar" />
          </Link>
          <div className="container-text right">
            <h1>{data.title}</h1>
            <p>{data.sub_title}</p>
          </div>
        </div>
      );
    }
  });
  let carousel = <Slider {...settings}>{cards}</Slider>;
  return carousel;
}
// function generatejumbotron(props) {
//   var tmp = [];
//   props.upcomingEvent.map((data, index) => {
//     tmp.push(
//       <Carousel fluid="true" className="w-100 carouselButton">
//         <Carousel.Item>
//           <Image
//             className="d-block w-100"
//             src={data.image}
//             fluid
//             style={{ maxHeight: "540px" }}
//           />
//           <Carousel.Caption>
//             <Link
//               to={`/EventDetail/${data.idEvent}`}
//               style={{ textDecoration: "none" }}
//             >
//               <Button className="sliderButton">Lihat lebih lanjut</Button>
//             </Link>
//           </Carousel.Caption>
//         </Carousel.Item>
//       </Carousel>
//     );
//     return "";
//   });
//   return tmp;
// }
export default HomeSlider_v2;
