import React, { Component } from "react";
import NavbarCenter from "../Component/NavigationBar_Com";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Footer from "../Component/Footer_Com";
import { getPartners } from "../Logic/Share_Logic";
import "../Style/Mitra_Styles.css";

class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: null,
    };
  }

  render() {
    return (
      <Grid container className="bg">
        <NavbarCenter />

        <Grid item xs={12} className="bgImage">
          <Grid container className="mitra-content">
            <Grid item xs={12} className="title">
              <h3 className="judul">Partner</h3>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {this.generatePartner(this.state.partner, 3, "merchant")}
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let resp = await getPartners();
    if ( resp[0] && resp[0].status === 200 ) {
      let result = [];
      const partners = resp[0].data;
      partners.forEach(res => {
        if ( res.is_show ) {
          result.push(res);
        }
      });
      this.setState({
        partner: result,
      });
    }
    else {
      console.log(resp);
    }
  }

  generatePartner(arr, col, StyleClass) {
    var card = [];
    if (arr == null) {
      return (
        <div
          className="merchantNull"
          style={{
            height: window.outerHeight * 0.75,
          }}
        >
        </div>
      );
    }
    arr.map((data) => {
      card.push(
        <Card.Img variant="top" src={data.logo} className={StyleClass} />
      );
      return "";
    });
    var carouselItem = [];
    // var cardDeck = [];
    for (var i = 0; i < card.length; i++) {
      carouselItem.push(
        <Grid item xs={6} sm={4} md={3}>
          {card[i]}
        </Grid>
      );
      // if (i % parseInt(col) === parseInt(col) - 1) {
      //   cardDeck.push(card[i]);
      //   carouselItem.push(<Row style={{
      //       minHeight: window.outerHeight*0.55,
      //     }} className="sponsorList">{cardDeck}</Row>);
      //   cardDeck = [];
      // } else {
      //   cardDeck.push(card[i]);
      // }
      // if (i === card.length - 1 && cardDeck.length > 0) {
      //   carouselItem.push(<Row  style={{
      //       minHeight: window.outerHeight*0.55,
      //     }}className="sponsorList">{cardDeck}</Row>);
      //   cardDeck = [];
      // }
    }
    return carouselItem;
  }
}

export default Partner;
