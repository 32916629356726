import React from "react";

import { Image } from "react-bootstrap";
function PengurusCard(props) {
  return (
    <>
      <div className="flip-card">
        <div
          className="flip-card-inner"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="pengurusActive-front">
            <Image
              key={props.content.id}
              src={props.content.foto}
              width="100%"
              style={{ borderRadius: 5 }}
            />
          </div>
          <div className="pengurusActive-back">
            <p>{props.content.nama}</p>
            <p style={{ textAlign: "center" }}>{props.content.jabatan}</p>
          </div>
        </div>
      </div>
      <div className="descMobile">
        <div className="namaExe">{props.content.nama}</div>
        <div>{props.content.jabatan}</div>
      </div>
    </>
  );
}

export default PengurusCard;
